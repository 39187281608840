import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';

export interface IMerchantDocument {
  document_name?: string;
  document_display_name?: string;
  document_status?: string;
  reason_id?: string;
  reason?: string;
}

export interface IMerchantProfile {
  first_name?: string;
  last_name?: string;
  business_legal_name?: string;
  merchant_id?: string;
  phone_number?: string;
  email?: string;
  country_calling_code?: string;
  merchant_name?: string;
  kyc_status?: string;
  country?: string;
  address_line1: string;
  address_line2: string;
  landmark: string;
  state: string;
  city: string;
  pincode: string;
}

export interface ISelectedMerchant {
  documents?: IMerchantDocument[];
  merchant_details?: IMerchantProfile;
}

const initialState: ISelectedMerchant = {};

export const verifyMerchantSlice = createSlice({
  name: 'merchantSlice',
  initialState,
  reducers: {
    setMerchantDocuments(state, action: PayloadAction<IMerchantDocument[]>) {
      const stateCopy = { ...state };
      const documents = action.payload;

      if (documents.length > 0) {
        stateCopy.documents = action.payload;
      }

      state.documents = stateCopy.documents;
      console.log('🚀 ~ setMerchantDocuments ~ state.documents:', state.documents);
    },
    setMerchantProfileDetails(state, action: PayloadAction<IMerchantProfile>) {
      const stateCopy = { ...state };
      const merchantProfile = action.payload;

      // const currCountry = stateCopy.find(s => s./ === action.payload[0]);

      if (!isEmpty(merchantProfile)) {
        stateCopy.merchant_details = action.payload;
      }

      state.merchant_details = stateCopy.merchant_details;
    },
  },
});

export const { setMerchantDocuments, setMerchantProfileDetails } = verifyMerchantSlice.actions;
export default verifyMerchantSlice;
