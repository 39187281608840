import React, { memo } from "react";
import { Input } from "@gofynd/nitrozen-react";
import CSS from "./input-text.atom.module.scss";

interface InputTextAtomProps {
  placeholder: string;
  id?: string;
  value?: string;
  onChange: (text: string) => unknown;
  maxLength?: number;
  className?: string;
  inputType?: string;
  disabled?: boolean;
}

function InputText({
  placeholder,
  id,
  value,
  onChange,
  maxLength,
  className = "",
  inputType = "text",
  disabled = false,
}: InputTextAtomProps): JSX.Element {
  return (
    <>
      <div className={CSS["input-text-wrapper"] + " " + CSS[className]}>
        <Input
          type={inputType}
          className={CSS["text-box-font-size"]}
          placeholder={placeholder}
          data-testid={id}
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e.target?.value)
          }
          maxLength={maxLength ?? undefined}
          disabled={disabled}
        />
      </div>
    </>
  );
}

const InputTextAtom = memo(InputText);
export default InputTextAtom;
