import { useState, useEffect } from 'react';
import { Table, Dropdown, Input, ToggleButton, DateInput } from '@gofynd/nitrozen-react';
import { DefaultTableData } from '../merchant-profile/molecules/table/table.constants';
import CSS from './external-endpoints.module.scss';
import { IApiListParams, getApiList } from './services/external-endpoints.service';
import InputTextAtom from '../../components/atoms/input-text/input-text.atom';
import SelectAtom from '../../components/atoms/select/select.atom';

import { getAPIStatus, IAPIDataState, IAPIStats } from '../../store/slices/external-endpoints.slice';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { getCompiledClasses } from '../../services/style.service';

const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

const ExternalEndpoints = () => {
  const [tableData, setTableData] = useState<any>([]);
  const apiData: IAPIStats[] = useSelector((state: RootState) => state.apiDashboard.items);
  const [loader, setLoader] = useState(true);

  const dispatch = useDispatch();

  const fetchApiList = async () => {
    try {
      const queryData: IApiListParams = {
        q: '',
        api_status: 'new',
        page_no: 1,
        page_size: 20,
      };
      // const apiData = await getApiList(queryData);
      // setTableData(apiData);

      const dummyData = [
        {
          api_name: 'tempApi122',
          success_rate: 98,
          is_active: true,
        },
        {
          api_name: 'tempApi1',
          success_rate: 50,
          is_active: true,
        },
        {
          api_name: 'tempApi1',
          success_rate: 83,
          is_active: true,
        },
        {
          api_name: 'tempApi1',
          success_rate: 45,
          is_active: true,
        },
        {
          api_name: 'tempApi1',
          success_rate: 72,
          is_active: true,
        },
        {
          api_name: 'tempApi1',
          success_rate: 64,
          is_active: true,
        },
        {
          api_name: 'tempApi1',
          success_rate: 20,
          is_active: true,
        },
        {
          api_name: 'tempApi1',
          success_rate: 70,
          is_active: true,
        },
      ];

      // dispatch(getAPIStatus(null));
      console.log('apiData');
      console.log(apiData);
      dispatch(getAPIStatus(null));
      setTableData(apiData);
    } catch (error) {
      console.log('🚀 ~ fetchApiList ~ error:', error);
    }
  };
  useEffect(() => {
    fetchApiList();
  }, []);
  return (
    <div className={css('merchant-profile-container')}>
      <div className={css('container-head')}>
        <div className={css('container-head-title')}>
          <div className={css('title')}>External Endpoints</div>
          <p className={css('subtitle')}>Enable/disable third party APIs </p>
        </div>
      </div>

      <div className={css('filter-bar')}>
        <div className={css('search_bar')}>
          <InputTextAtom placeholder="Search by API name" onChange={() => {}}></InputTextAtom>
        </div>
        <div className={css('kyc-filter')}>
          <SelectAtom
            value={''}
            items={[
              {
                text: 'ALL',
                value: 1,
              },
              {
                text: 'ACTIVE',
                value: 2,
              },
              {
                text: 'INACTIVE',
                value: 3,
              },
            ]}
            onChange={() => {}}
            placeholder="Status"
            id="field-control-all-status"
          />
          {/* <Dropdown placeholder="KYC Status" items={kycStatuses}></Dropdown> */}
        </div>
        <div className={css('date-range')}></div>
        <DateInput
          id="range-field"
          isRange={true}
          label=""
          onConfirmRange={() => {}}
          rangeConfig={{
            end: new Date(),
            max: new Date(),
            min: '',
            start: new Date(),
          }}
          useDatePicker
        />
      </div>

      <div className={css('table')}>
        <Table
          rowStyle="simple"
          tableHeader={[
            {
              name: 'api_name',
              value: 'API Name',
              width: '10%',
            },
            {
              name: 'success_rate',
              value: 'Success Rate',
              width: '10%',
            },
            {
              name: 'is_active',
              type: 'number',
              value: 'Active/Inactive',
              width: '10%',
            },
          ]}
          tableRow={tableData.map(d => {
            return {
              ...d,
              is_active: (
                <div>
                  <ToggleButton
                    id="React0"
                    labelText=""
                    onIconClick={() => {}}
                    onToggle={function noRefCheck() {}}
                    size="medium"
                  />
                </div>
              ),
            };
          })}
        ></Table>
      </div>
    </div>
  );
};

export default ExternalEndpoints;
