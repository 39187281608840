import { Dropdown, SvgIcChevronDown, SvgIcChevronUp } from '@gofynd/nitrozen-react';
import { useEffect, useState } from 'react';
import CSS from './select-country.module.scss';
import { ReactComponent as FlagIN } from '../../../assets/flag-in.svg';
import { ReactComponent as FlagUS } from '../../../assets/flag-us.svg';
import { ReactComponent as FlagAE } from '../../../assets/flag-ae.svg';
import { ReactComponent as FlagSA } from '../../../assets/flag-sa.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ICountryConfig, IFormFieldConfig, setFieldsForCountry } from '../../../store/slices/form-builder.slice';
import { getCompiledClasses } from '../../../services/style.service';
import Accordion from '../../../components/atoms/accordion/accordion.atom';
import { isEmpty, truncate } from 'lodash';
import { getBasicDetailFields, getFormsForApplication } from '../form-builder.service';

import Loader from 'components/atoms/loader/loader.atom';

const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

export const COUNTRY_FLAGS = {
  India: <FlagIN></FlagIN>,
  'United States of America': <FlagUS></FlagUS>,
  'United Arab Emirates': <FlagAE></FlagAE>,
  'The Kingdom of Saudi Arabia': <FlagSA></FlagSA>,
};

// export const basicDetailFields = {
//   first_name: 'First Name',
//   last_name: 'Last Name',
//   business_legal_name: 'Business Legal Name',
//   business_type: 'Business Type',
//   phone_number: 'Phone Number',
//   email: 'Email ID',
// };

const BasicDetailFields = ({ country }: { country: ICountryConfig }) => {
  const dispatch = useDispatch();

  const [basicDetailFields, setBasicDetailFields] = useState([]);
  const [initBasicDetailsFormFields, setInitBasicDetailsFormFields] = useState([]);

  const [dataForCountryLoaded, setDataForCountryLoaded] = useState(false);

  const getFormFieldsBasicDetails = async () => {
    const basicDetails = await getBasicDetailFields(country.country_alias);
    return basicDetails.data.data;
  };

  useEffect(() => {
    let rawFields = [];
    (async () => {
      // get list of basic detail fields
      const formFieldsBasicDetails = await getFormFieldsBasicDetails();
      console.log('STAGE2 ~ Getting list of form fields:', formFieldsBasicDetails);

      rawFields = formFieldsBasicDetails;
      setBasicDetailFields([
        ...rawFields.map(f => ({
          field_name: f.field_name,
          is_required: f.is_required || true,
          field_display_name: f.field_display_name,
        })),
      ]);

      setInitBasicDetailsFormFields([
        ...rawFields.map(f => ({
          field_name: f.field_name,
          is_required: f.is_required || true,
          field_display_name: f.field_display_name,
        })),
      ]);

      const formsForApplication = await getFormsForApplication();
      console.log('STAGE2: formsForApplication.data', formsForApplication.data);

      // pre load selected fields from API
      const countrySelected = formsForApplication.data?.data.find(
        c => c.country.country_alias === country.country_alias
      );

      if (!isEmpty(countrySelected) && countrySelected?.basic_details?.fields?.length > 0) {
        console.log('STAGE2: rawFields', rawFields);

        dispatch(
          setFieldsForCountry([
            country.country_name,
            [
              ...countrySelected?.basic_details?.fields.map(f => ({
                field_name: f.field_name,
                is_required: f.is_required,
                field_display_name:
                  f.field_display_name || rawFields.find(r => r.field_name === f.field_name)?.field_display_name,
              })),
            ],
          ])
        );
      }

      setDataForCountryLoaded(true);
    })();

    return () => {};
  }, []);

  const onBasicDetailSearchInputChange = e => {
    console.log(e);

    if (e.text === '') {
      setBasicDetailFields([...initBasicDetailsFormFields]);
    } else {
      let searchTerms = [];
      if (e.text.includes(' ')) {
        searchTerms = e.text.split(' ');
      } else {
        searchTerms = [e.text];
      }
      const filterResults = [
        ...basicDetailFields.filter(filter =>
          searchTerms.every(term => filter.field_display_name.toLowerCase().includes(term.toLowerCase()))
        ),
      ];

      setBasicDetailFields([...filterResults]);
    }
  };
  return (
    <div>
      {/* Loader  */}
      {!dataForCountryLoaded && <Loader />}
      
      {dataForCountryLoaded && (
        <div>
          <div className={css('pad-1')}>
            <Dropdown
              addOption={false}
              id="basic-details"
              enableSelectAll={true}
              items={[
                ...basicDetailFields.map(f => ({
                  text: f.field_display_name,
                  value: f.field_name,
                })),
              ]}
              label="Basic Detail Field"
              value={country.fields.map(i => i.field_name)}
              multiple
              onChange={event => {
                // setFieldsSelected(event);
                console.log('event');
                console.log(event);
                const arr = [...event];
                const fieldsToSet = [];
                arr.map(e => {
                  const field = basicDetailFields.find(f => f.field_name === e);
                  fieldsToSet.push({
                    field_name: field.field_name,
                    is_required: field.is_required,
                    field_display_name: field.field_display_name,
                  });
                });
                dispatch(setFieldsForCountry([country.country_name, [...fieldsToSet]]));
              }}
              onScroll={function noRefCheck() {}}
              onSearchInputChange={e => onBasicDetailSearchInputChange(e)}
              placeholder="Select Basic Detail Fields"
              searchable={true}
            />
          </div>
          <div className={css('d-flex gap-2 margin-1')}>
            {country.fields.length > 0 &&
              country.fields.map((nested_item, index) => (
                <div className={css(`pad-1 gap-1 d-flex form-builder-box`)} key={index}>
                  <span>{nested_item.field_display_name}</span>
                  <span
                    onClick={() => {
                      const countryFields: IFormFieldConfig[] = Array.from(country.fields);
                      countryFields.splice(index, 1);
                      console.log('country.fields after deleting', countryFields);
                      dispatch(setFieldsForCountry([country.country_name, [...countryFields]]));
                    }}
                    className={css('hover-cursor-pointer')}
                  >
                    x
                  </span>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

const AccordionHeader = ({ country }) => {
  return (
    <div className={css('d-flex-center gap-1 pad-1')}>
      <div className={css('flag-container')}>{COUNTRY_FLAGS[country.country_name]}</div>
      <span> {country.country_name} </span>
    </div>
  );
};

export const BasicDetails = () => {
  const countryList: ICountryConfig[] = useSelector((state: RootState) => state.formConfig.form_config);

  console.log('countryList');
  console.log(countryList);

  return (
    <div className={css('d-flex-column gap-2')}>
      {countryList.length === 0 && <span>Please select countries first</span>}

      {countryList.map((country, index) => {
        return (
          <Accordion
            header={<AccordionHeader country={country}></AccordionHeader>}
            children={<BasicDetailFields country={country} />}
            isOpen={true}
          ></Accordion>
        );
      })}
    </div>
  );
};

export default BasicDetails;
