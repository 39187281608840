import { Route, Routes } from 'react-router-dom';
import SalesChannelPage from '../pages/sales-channel/sales-channel.component';
import Layout from '../components/templates/layout';
import MerchantProfile from '../pages/merchant-profile';
import FormBuilder from '../pages/form-builder';
import ExternalEndpoints from '../pages/external-endpoints/ExternalEndpoints';
import VerifyMerchant from '../pages/verify-merchant';
import BasicDetails from '../pages/form-builder/components/basic-details.component';
import BusinessDetails from '../pages/form-builder/components/business-details.component';
import SelectCountries from '../pages/form-builder/components/select-country.component';
import VerificationDetails from '../pages/form-builder/components/verification-details.component';
import FooterConfiguration from '../pages/form-builder/components/footer-configuration.component';
import { FormBuildDone } from '../pages/form-builder/components/form-build-done';

const Router = () => {
  return (
    <Routes>
      <Route path="/company/:company_id" element={<SalesChannelPage />}></Route>
      <Route path="/" element={<Layout />}>
        <Route path="/merchant-profile" element={<MerchantProfile />}></Route>
        <Route path="/merchant-profile/verify-merchant/:merchant_id" element={<VerifyMerchant />}></Route>
        <Route path="/external-endpoints" element={<ExternalEndpoints />}></Route>
        <Route path="/form-builder" element={<FormBuilder />}>
          <Route path="/form-builder/select-countries" index element={<SelectCountries />}></Route>
          <Route path="/form-builder/basic-details" element={<BasicDetails />}></Route>
          <Route path="/form-builder/business-details" element={<BusinessDetails />}></Route>
          <Route path="/form-builder/verification-details" element={<VerificationDetails />}></Route>
          <Route path="/form-builder/submit-form" element={<FooterConfiguration />}></Route>
          <Route path="/form-builder/done" element={<FormBuildDone />}></Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default Router;
