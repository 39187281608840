// import axios from '../../../services/http.service';
import axios from '../../services/http.service';
import urlJoin from 'url-join';
import { platformBaseUrl } from '../../utils/constants';
import { getCompanyId, getApplicationInfo } from '../../utils';
import { ICountryConfig } from '../../store/slices/form-builder.slice';

const supportedCountryList = [
  {
    country_name: 'India',
    country_alias: 'IND',
  },
  {
    country_name: 'United States of America',
    country_alias: 'USA',
  },
  {
    country_name: 'United Arab Emirates',
    country_alias: 'UAE',
  },
  {
    country_name: 'The Kingdom of Saudi Arabia',
    country_alias: 'KSA',
  },
];

export const upsertCountries = async (payload: ICountryConfig[]) => {
  const url = urlJoin(platformBaseUrl, '/form-builder/v1/schemas');

  const countries = payload;
  // const countries = ['India', 'United States of America'];

  console.log("countries")
  console.log(countries)
  const requestPayload = {
    form_schemas: [
      ...countries.map(c => {
        const obj = {
          country: c.country,
        };
        if (c.form_id) {
          obj['form_id'] = c.form_id;
          console.log('Upserting country with form id', c.form_id);
        } else {
          console.log('Upserting country without form id');
        }

        return obj;
      }),
    ].map(s => ({ ...s, company_id: getCompanyId(), application_id: getApplicationInfo().applicationId })),
  };

  return await axios.put(url, requestPayload);
};

export const upsertFormFields = async payload => {
  const url = urlJoin(platformBaseUrl, '/form-builder/v1/schemas');

  const requestPayload = payload;

  return await axios.put(url, requestPayload);
};

export const buildForm = async form_id => {
  console.log('🚀 ~ buildForm ~ platformBaseUrl:', platformBaseUrl);
  const url = urlJoin(platformBaseUrl, '/form-builder/v1/schemas/storefront');
  const requestPayload = {
    form_schemas: [
      {
        form_id,
        company_id: getCompanyId(),
        application_id: getApplicationInfo().applicationId,
      },
    ],
  };
  return await axios.post(url, requestPayload);
};

export const getCountries = async () => {
  try {
    const url = urlJoin(platformBaseUrl, '/form-builder/v1/extra/countries');
    return await axios.get(url);
  } catch (error) {
    console.error('🚀 ~ error', error);
  }
};

const getFormFields = async (stage, country) => {
  try {
    console.log('🚀 ~ getFormFields ~ platformBaseUrl:', platformBaseUrl);
    const url = urlJoin(platformBaseUrl, '/form-builder/v1/extra/form-fields');
    const queryParams = {
      stage,
      country,
      application_id: getApplicationInfo().applicationId,
    };

    return await axios.get(url, { params: queryParams });
  } catch (error) {
    console.error('🚀 ~ error', error);
  }
};

export const getBasicDetailFields = async country => await getFormFields('basic_details', country);

export const getBusinessDetailFields = async country => await getFormFields('business_details', country);

export const getVerificationDetailFields = async country => {
  try {
    console.log('🚀 ~ getVerificationDetailFields ~ platformBaseUrl:', platformBaseUrl);
    const url = urlJoin(platformBaseUrl, '/form-builder/v1/extra/business-documents');

    const queryParams = {
      country,
    };
    return await axios.get(url, { params: queryParams });
  } catch (error) {
    console.error(error);
  }
};

export const getFormsForApplication = async () => {
  try {
    const url = urlJoin(platformBaseUrl, '/form-builder/v1/schemas');
    const queryParams = {
      company_id: getCompanyId(),
      application_id: getApplicationInfo().applicationId,
    };

    return await axios.get(url, { params: queryParams });
  } catch (error) {
    console.error(error);
  }
};

export const deleteForm = async form_id => {
  try {
    const url = urlJoin(platformBaseUrl, '/form-builder/v1/schemas');
    const queryParams = {
      form_id,
    };
    return await axios.delete(url, { params: queryParams });
  } catch (error) {
    console.error(error);
  }
};
