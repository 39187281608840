import { SvgIcSuccessColored } from '@gofynd/nitrozen-react';
import { getCompiledClasses } from '../../../services/style.service';

const css = classNames => getCompiledClasses(classNames);

export const SuccessDialog = ({ title, message }) => {
  return (
    <>
      <div className={css('d-flex-column d-flex-center gap-2')}>
        <SvgIcSuccessColored size={100} />

        <p className={css('fs-18 bold')}>{title}</p>

        <p className={css('fs-12 pad-side-10 text-center')}>{message}</p>
      </div>
    </>
  );
};

export const FormBuildDone = () => {
  return (
    <>
      <SuccessDialog
        title="Form Saved and Published"
        message="Please take a moment to visit the storefront and review your form to ensure everything is displaying as expected. If you notice any discrepancies or encounter any issues, do not hesitate to reach out to our support team for assistance."
      />
    </>
  );
};

export const FormBuildSaved = () => {
  return (
    <>
      <SuccessDialog
        title="Form Saved"
        message="Your form has been saved successfully. Please note that form needs to be published in order to be reflected on storefront."
      />
    </>
  );
};
