import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '..';

interface Pagination {
  current: number;
  has_next: boolean;
  item_total: number;
  size: number;
  type: string;
}

export interface IFieldConfig {
  field_name?: string;
  type?: string;
  is_mandatory?: boolean;
}

export interface IFormFieldConfig {
  field_id?: string;
  field_name?: string;
  field_placeholder?: string;
  is_required?: boolean;
  field_display_name?: string;
  // field_id?: string;
  // field_type: string;
  // associated_to: string;
  // ui_widget: string;
  // field_placeholder: string;
  // field_properties?: {
  //   type?: string;
  //   enum?: string[];
  //   minLength?: number;
  //   maxLength?: number;
  //   pattern?: string;
  //   dependencies?: object;
  //   meta?: object;
  // };
  // application_id: string;
  // company_id: string;
}

export interface IBusinessFieldConfig {
  field_name?: string;
  type?: string;
  is_mandatory?: boolean;
  is_required?: boolean;
  field_display_name?: string;
}

export interface IDocumentVerification {
  proof_of_address?: IDocument[];
  proof_of_identity?: IDocument[];
  proof_of_business?: IDocument[];
}
export interface IDocument {
  document_name?: string;
  document_display_name?: string;
  document_display_name_auto?: string;
  document_images?: string[];
  is_manual?: boolean;
  is_auto?: boolean;
}

export interface IApprovalMethod {
  verification_type: string;
  input_title: string;
}
// export interface IVerificationDocument {
//   document_name: string;
//   approval_methods: IApprovalMethod[];
// }

export interface IRule {
  conditions?: string[];
  operator?: string;
}
export interface IRules {
  'rule#1'?: IRule;
  'rule#2'?: IRule;
  parent_conditions?: IRule;
}

export interface ICountry {
  country_name?: string;
  country_alias?: string;
}
export interface ICountryConfig {
  form_id?: string;
  country_name?: string;
  country_alias?: string;
  country?: ICountry;
  fields?: IFormFieldConfig[];
  business_fields?: IFormFieldConfig[];
  verification_documents?: IDocumentVerification;
  document_list?: IDocument[];
  rules?: IRules;
}

export interface IFormConfiguration {
  form_config: ICountryConfig[];
}

export interface PAAddCountryAction {
  country_name?: string;
  field: IFormFieldConfig;
}

export interface PARemoveCountryAction {
  country_name?: string;
  field_name: string;
}

const initialState: IFormConfiguration = {
  form_config: [],
};

export const formConfigSlice = createSlice({
  name: 'formConfig',
  initialState,
  reducers: {
    addCountry(state, action: PayloadAction<[string, string]>) {
      console.log(action);

      // if country name does not exists, then only add
      if (!state.form_config.find(s => s.country_name === action.payload[0])) {
        state.form_config.push({
          country_name: action.payload[0],
          country_alias: action.payload[1],
          country: {
            country_name: action.payload[0],
            country_alias: action.payload[1],
          },
          fields: [],
          business_fields: [],
          document_list: [],
          verification_documents: {},
          rules: {},
        });
      }
    },
    resetCountries(state) {
      state.form_config = [];
    },
    removeCountry(state, action: PayloadAction<string>) {
      const stateCopy = [...state.form_config];

      const currCountry = stateCopy.find(s => s.country_name === action.payload || s.country_alias === action.payload);
      if (currCountry) {
        stateCopy.splice(stateCopy.indexOf(currCountry), 1);
      }
      state.form_config = stateCopy;

      // state.form_config = state.form_config.filter(
      //   s => !(s.country_name === action.payload) || !(s.country_alias === action.payload)
      // );
    },

    setCountries(state, action: PayloadAction<ICountry[]>) {
      const newCountries: ICountryConfig[] = action.payload;

      const empty: ICountryConfig[] = [];

      for (const c of newCountries) {
        const obj = {
          country_name: c.country_name,
          country_alias: c.country_alias,
          country: {
            country_name: c.country_name,
            country_alias: c.country_alias,
          },
          fields: [],
          business_fields: [],
          document_list: [],
          verification_documents: {},
          rules: {},
        };
        if (c.form_id) obj['form_id'] = c.form_id;
        empty.push(obj);
      }
      state.form_config = [...empty];
    },

    addFieldForCountry(state, action: PayloadAction<PAAddCountryAction>) {
      const stateCopy = [...state.form_config];
      const currCountry = stateCopy.find(s => s.country_name === action.payload.country_name);
      if (currCountry) {
        currCountry.fields.push(action.payload.field);
        state.form_config = stateCopy;
        // sort fields by field_name
      }
    },
    // removeFieldForCountry(state, action: PayloadAction<PARemoveCountryAction>) {
    //   const stateCopy = [...state.form_config];
    //   const currCountry = stateCopy.find(s => s.country_name === action.payload.country_name);
    //   if (currCountry) {
    //     currCountry.fields = currCountry.fields.filter(s => !(s === action.payload.field_name));
    //   }
    //   state.form_config = stateCopy;
    // },

    setFieldsForCountry(state, action: PayloadAction<[string, IFormFieldConfig[]]>) {
      const stateCopy = [...state.form_config];

      const currCountry = stateCopy.find(s => s.country_name === action.payload[0]);

      if (currCountry) {
        currCountry.fields = action.payload[1];
      }
      state.form_config = stateCopy;
    },
    setBusinessFieldsForCountry(state, action: PayloadAction<[string, IFormFieldConfig[]]>) {
      const stateCopy = [...state.form_config];

      const currCountry = stateCopy.find(s => s.country_name === action.payload[0]);

      if (currCountry) {
        currCountry.business_fields = action.payload[1];
      }
      state.form_config = stateCopy;
    },

    setVerificationDocumentsForCountry(state, action: PayloadAction<[string, IDocumentVerification]>) {
      const stateCopy = [...state.form_config];

      const currCountry = stateCopy.find(s => s.country_name === action.payload[0]);

      if (currCountry) {
        currCountry.verification_documents = action.payload[1];
      }
      state.form_config = stateCopy;
    },

    setRulesForCountry(state, action: PayloadAction<[string, IRules]>) {
      const stateCopy = [...state.form_config];

      console.log('setRulesForCountry - action.payload[1]');
      console.log('setRulesForCountry', action.payload[1]);
      const currCountry = stateCopy.find(s => s.country_name === action.payload[0]);
      console.log('🚀 ~ setRulesForCountry ~ action.payload[0]:', action.payload[0]);
      console.log('🚀 ~ setRulesForCountry ~ currCountry:', currCountry);
      if (currCountry) {
        currCountry.rules = action.payload[1];
      }
      state.form_config = stateCopy;
    },

    setFormIdForCountry(state, action: PayloadAction<[string, string]>) {
      const stateCopy = [...state.form_config];
      const [countryName, form_id] = action.payload;

      const currCountry = stateCopy.find(s => s.country_name === countryName || s.country_alias === countryName);
      if (currCountry && form_id) {
        currCountry.form_id = form_id;
      }
      state.form_config = stateCopy;
    },

    setVerificationDocumentsForCategoryForCountry(state, action: PayloadAction<[string, string, IDocument[]]>) {
      const stateCopy = [...state.form_config];

      const currCountry = stateCopy.find(
        s => s.country_name === action.payload[0] || s.country_alias === action.payload[0]
      );
      console.log('🚀 ~ currCountry:', currCountry);
      if (currCountry) {
        currCountry.verification_documents[action.payload[1]] = action.payload[2];
      }
      state.form_config = stateCopy;
    },
  },
});

export const {
  addCountry,
  removeCountry,
  resetCountries,
  addFieldForCountry,
  setFieldsForCountry,
  setBusinessFieldsForCountry,
  setRulesForCountry,
  setFormIdForCountry,
  setCountries,
  setVerificationDocumentsForCountry,
  setVerificationDocumentsForCategoryForCountry,
} = formConfigSlice.actions;

export const formBuilderSelector = (state: RootState) => state.formConfig;

export default formConfigSlice;
