/* eslint-disable jsx-a11y/anchor-is-valid */
import { Table, Dropdown, DateInput, Badge, Pagination, ToggleButton } from '@gofynd/nitrozen-react';
import { DefaultTableData } from '../merchant-profile/molecules/table/table.constants';
import CSS from './merchant-profile.module.scss';
import { ITableRow } from './molecules/table/table.molecule.types';
import { useEffect, useState, useCallback, useMemo } from 'react';
import {
  exportOptions,
  getMerchants,
  KYC_STATUS_DROPDOWN,
  merchantTableHeaders,
} from './services/merchant-profile.service';
import InputTextAtom from '../../components/atoms/input-text/input-text.atom';

import SideDrawer from '../../components/templates/side-drawer/side-drawer.template';
import ExportHistory from './components/export-history.component';
import { ReactComponent as ViewIcon } from '../../assets/eye-icon.svg';
import { useNavigate } from 'react-router-dom';

import { getCompanyId, getApplicationInfo } from '../../utils';

import { getCompiledClasses } from '../../services/style.service';
import { ReactComponent as FlagIN } from '../../assets/flag-in.svg';
import { ReactComponent as FlagUS } from '../../assets/flag-us.svg';
import { ReactComponent as FlagAE } from '../../assets/flag-ae.svg';
import { ReactComponent as FlagSA } from '../../assets/flag-sa.svg';

import debounce from 'lodash/debounce';
import { addBinding, getAllBindings } from '../sales-channel/services/sales-channel.service';

/** Absolute Imports */

/**
 * TODO: Convert every relative imports to absolute imports
 */

import Loader from 'components/atoms/loader/loader.atom';

const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

enum ModeEnum {
  MODE_REGULAR = 'regular',
  MODE_CURSOR = 'cursor',
}
enum TypeEnum {
  TYPE_DEFAULT = 'default',
  TYPE_TOP = 'top',
}
enum SizeEnum {
  SIZE_LARGE = 'large',
  SIZE_SMALL = 'small',
}

export const COUNTRY_FLAGS = {
  IND: <FlagIN></FlagIN>,
  USA: <FlagUS></FlagUS>,
  UAE: <FlagAE></FlagAE>,
  KSA: <FlagSA></FlagSA>,
};

interface IPagination {
  limit?: number;
  total?: number;
  current?: number;
  currentTotal?: number;
  prevPage?: string; // kept string because of Nitrozen
  nextPage?: string;
  currentPage?: string;
}

interface IFilters {
  q?: string;
  kyc_status?: string[];
  country?: string[];
  show_rejected?: boolean;
  kyc_status_disabled?: boolean;
  date_range_start?: Date;
  date_range_end?: Date;
  pagination?: IPagination;
}

export const countryAliases = {
  India: 'IND',
  'United States of America': 'USA',
  'United Arab Emirates': 'UAE',
  'The Kingdom of Saudi Arabia': 'KSA',
};

const countryNames = {
  IND: 'India',
  USA: 'United States of America',
  UAE: 'United Arab Emirates',
  KSA: 'The Kingdom of Saudi Arabia',
};

const countryDropdown = Object.keys(countryNames).map(key => ({ value: key, text: countryNames[key] }));

export const kycStatusMasterList = [
  {
    value: 'new',
    text: 'NEW',
    badge: (
      <Badge
        style={{
          color: 'blue',
          border: '1px blue solid',
        }}
        labelText={'NEW'}
      />
    ),
  },
  {
    value: 'approved',
    text: 'APPROVED',
    badge: <Badge labelText={'APPROVED'} />,
  },
  {
    value: 'rejected',
    text: 'REJECTED',
    badge: <Badge labelText={'REJECTED'} state={'error'} />,
  },
  {
    value: 'on_hold',
    text: 'ON HOLD',
    badge: (
      <Badge
        labelText={'ON HOLD'}
        onClickHandler={function noRefCheck() {}}
        style={{
          color: '#7d2f08',
          border: '1px solid #7d2f08',
        }}
      />
    ),
  },
  {
    value: 'partially_approved',
    text: 'PARTIALLY APPROVED',
    badge: (
      <Badge
        labelText={'PARTIALLY APPROVED'}
        onClickHandler={function noRefCheck() {}}
        style={{
          color: 'lightseagreen',
          border: '1px solid lightseagreen',
        }}
      />
    ),
  },
];

const MerchantProfile = () => {
  const [merchantDataLoading, setMerchantDataLoading] = useState(true);
  // const [merchants, setMerchants] = useState<ITableRow[]>(DefaultTableData);
  const [rows, setRows] = useState<ITableRow[]>([]);
  const [filteredRows, setFilteredRows] = useState(rows);

  const [isExportHistoryOpen, setIsExportHistoryOpen] = useState(false);

  const [pagination, setPagination] = useState<IPagination>({
    limit: 10,
    current: 1,
  });
  const [filters, setFilters] = useState<IFilters>({
    kyc_status: ['NEW', 'APPROVED', 'ON_HOLD', 'PARTIALLY_APPROVED'],
    country: ['IND', 'USA', 'UAE', 'KSA'],
    show_rejected: false,
    kyc_status_disabled: false,
    q: '',
  });

  const kycStatusKeyToDisplayNameMap = {
    new: 'NEW',
    rejected: 'REJECTED',
    partially_approved: 'PARTIALLY APPROVED',
    on_hold: 'ON HOLD',
    approved: 'APPROVED',
  };
  const navigate = useNavigate();

  const badgeStates = {
    new: 'disable',
    rejected: 'error',
    'partially approved': 'warn',
    'on hold': 'warn',
    approved: '',
  };

  const onKYCStatusChange = e => {
    console.log('KYC Status Change', e);
    setFilters({
      ...filters,
      kyc_status: e,
    });
  };

  const onCountryChange = e => {
    console.log('Country Change', e);
    setFilters({
      ...filters,
      country: e,
    });
  };

  const onShowRejectedToggleClick = e => {
    const valueToSet = !filters.show_rejected;
    console.log('onShowRejectedToggleClick', valueToSet);

    setFilters({
      ...filters,
      show_rejected: valueToSet,
      kyc_status: valueToSet ? ['REJECTED'] : ['NEW', 'APPROVED', 'ON_HOLD', 'PARTIALLY_APPROVED'],
      kyc_status_disabled: valueToSet ? true : false,
    });
  };
  // useEffect(() => {
  //   return () => {
  //     debouncedResults.cancel();
  //   };
  // });

  const handleSearchInputChange = e => {
    console.log('handleSearchInputChange::::', e);
    setFilters({
      ...filters,
      q: e,
    });
  };

  const handlePageChange = e => {
    console.log('pagination :::: ', e);
    console.log('pagination :::: ', {
      ...pagination,
      ...e,
    });
    console.log('Pagination Changed ::', pagination);

    (async () => {
      setMerchantDataLoading(true);
      const res = await getMerchants(
        filters.q,
        e.current,
        e.limit,
        getApplicationInfo().applicationId,
        getCompanyId(),
        filters.kyc_status || [],
        filters.country
      );
      console.log(res);
      setFilteredRows(res?.data.data.documents);
      setMerchantDataLoading(false);

      if (filters.kyc_status.length === 0 || filters.country.length === 0) {
        setRows([]);
        setFilteredRows([]);
      }

      // return res.data?.data;
    })();
    // setPagination({
    //   ...pagination,
    //   ...e,
    // });
  };

  // useEffect(() => {

  //   return () => {};
  // }, [pagination]);

  const getMerchantsData = async () => {
    try {
      const res = await getMerchants(
        filters.q,
        pagination.current,
        pagination.limit,
        getApplicationInfo().applicationId,
        getCompanyId(),
        filters.kyc_status,
        filters.country
      );
      console.log(res);
      return res.data?.data;
    } catch (error) {
      console.error('ERROR: getMerchantsData API Call', error);
      throw Error(error);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        console.log('Getting all bindings available for the application');
        const response = await getAllBindings();
        console.log('response');
        console.log(response);

        const addedInjectableTags = await addBinding();
        console.log('🚀 ~ addedInjectableTags:', addedInjectableTags);
      } catch (error) {
        console.error('Failed to fetch bindings', error);
      }
    })();

    (async () => {
      const data = await getMerchantsData();
      // setMerchants(data?.documents);
      console.log('Init: getMerchantsData', data);
      setFilteredRows(data?.documents);
      setPagination({
        ...pagination,
        total: data?.page?.item_total,
        current: data?.page?.current,
        currentTotal: data?.page?.item_total,
      });
      setMerchantDataLoading(false);

      if (filters.kyc_status.length === 0 || filters.country.length === 0) {
        setRows([]);
        setFilteredRows([]);
      }
    })();

    return () => {};
  }, []);

  useEffect(() => {
    setMerchantDataLoading(true);

    console.log('All Filters ::::', filters);

    (async () => {
      console.log(':::: Calling API');
      const res = await getMerchantsData();

      // console.log(res.data?.data?.documents);
      const merchantsReceived = res.documents;

      const filteredData = merchantsReceived.map(m => ({
        merchant_id: m.merchant_id,
        first_name: m.first_name,
        last_name: m.last_name,
        business_legal_name: m.business_legal_name,
        phone_number: m.phone_number,
        kyc_status: m.kyc_status,
        country_calling_code: m.country_calling_code,
        country: m.country,
      }));
      console.log('filteredData');
      console.log(filteredData);
      console.log('came here');

      setFilteredRows(filteredData);

      // setMerchants(filteredData);
      setRows(filteredData);
      setPagination({
        ...pagination,
        total: res.page?.item_total,
        current: res.page?.current,
      });
      // setFilteredRows(Array.from(filteredData.slice(0, filters.pagination.limit)));
      setMerchantDataLoading(false);

      if (filters.kyc_status.length === 0 || filters.country.length === 0) {
        setRows([]);
        setFilteredRows([]);
      }
    })();

    // setMerchantDataLoading(false);
  }, [filters]);

  const debouncedResults = debounce(handleSearchInputChange, 500);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });
  return (
    <div className={css('merchant-profile-container')}>
      {/* <Outlet></Outlet> */}
      <div className={css('container-head')}>
        <div className={css('container-head-title')}>
          <div className={css('title')}>Merchants</div>
          <p className={css('subtitle')}>Approve or reject KYC from listing</p>
        </div>
      </div>
      <div className={css('filter-bar radius-half fs-12')}>
        <div className={css('search-bar')}>
          <InputTextAtom
            placeholder="Search by email or phone number"
            onChange={e => debouncedResults(e)}
            value={filters.q}
          ></InputTextAtom>
        </div>

        <div className={css('country-filter')}>
          <Dropdown
            enableSelectAll={true}
            id="merchantProfileCountryDropdown"
            items={countryDropdown}
            value={filters.country}
            multiple
            onChange={e => onCountryChange(e)}
            placeholder="Select Country"
            searchable={false}
            label=""
          />
        </div>
        <div className={css('kyc-filter')}>
          <Dropdown
            disabled={filters.kyc_status_disabled}
            addOption={false}
            enableSelectAll={true}
            id="KYC_STATUS_DROPDOWN"
            items={KYC_STATUS_DROPDOWN}
            value={filters.kyc_status}
            multiple
            onChange={e => onKYCStatusChange(e)}
            onScroll={function noRefCheck() {}}
            placeholder="Select KYC Statuses"
            searchable={false}
            label=""
          />
        </div>
        <div className={css('show-rejected pad-side-2')}>
          <ToggleButton
            id="ToggleRejectedMerchant"
            // labelText="Show Rejected Merchants"
            onIconClick={() => {}}
            onToggle={e => onShowRejectedToggleClick(e)}
            size="medium"
            value={filters.show_rejected}
            labelText="Show Rejected Merchants"
          />
          {/* <span>Show Rejected Merchants</span> */}
        </div>
      </div>
      <div className={css('table')}>
        {merchantDataLoading && <Loader />}

        {!merchantDataLoading && (
          <>
            {filteredRows.length > 0 && (
              <Table
                rowStyle="simple"
                tableHeader={merchantTableHeaders}
                tableRow={filteredRows.map(d => {
                  return {
                    ...d,
                    // country: COUNTRY_FLAGS[d.country], //TODO add country flag later
                    kyc_status: kycStatusMasterList.find(m => m.value === d.kyc_status)?.badge || <>{d.kyc_status}</>,
                    phone_number: `${d.country_calling_code} ${d.phone_number}`,
                    action: (
                      <div className={css('hover-cursor-pointer')}>
                        <ViewIcon
                          onClick={() => {
                            navigate('/merchant-profile/verify-merchant/' + d.merchant_id);
                          }}
                        />
                      </div>
                    ),
                  };
                })}
              ></Table>
            )}

            {filteredRows.length === 0 && (
              <div className={css('d-flex-center')}>
                <p className={css('color-red marging-2 padding-2 fs-14')}>No merchants found</p>
              </div>
            )}
          </>
        )}
      </div>
      {filteredRows.length > 0 && (
        <>
          <div className={css('pagination-container')}>
            <Pagination
              id="merchant_profile_pagination"
              name="Merchant Profile Pagination"
              type={TypeEnum.TYPE_DEFAULT}
              onChange={e => handlePageChange(e)}
              defaultPageSize={10}
              pageSizeOptions={[10, 15, 20]}
              value={pagination}
              style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}
            />
          </div>
        </>
      )}
      <div className={css('side-drawer-export-history')}>
        {isExportHistoryOpen && (
          <SideDrawer
            widthPercentage={60}
            component={<ExportHistory />}
            heading="Export History"
            setShowDrawer={() => {
              setIsExportHistoryOpen(false);
            }}
            showDrawer={isExportHistoryOpen}
          ></SideDrawer>
        )}
      </div>
    </div>
  );
};
export default MerchantProfile;
