import { Button, Dropdown, SvgIcConfirm, SvgIcEdit, SvgIcEditPen } from '@gofynd/nitrozen-react';
import CSS from './form-builder.module.scss';
import SelectCountries from './components/select-country.component';
import BasicDetails from './components/basic-details.component';
import BusinessDetails from './components/business-details.component';
import { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import VerificationDetails from './components/verification-details.component';
import { RootState } from '../../store';
import { useDispatch, useSelector } from 'react-redux';
import { getCompiledClasses } from '../../services/style.service';
import { ICountryConfig, setFormIdForCountry } from '../../store/slices/form-builder.slice';
import { upsertFormFields, upsertCountries, buildForm, getFormsForApplication } from './form-builder.service';
import { getCompanyId, getApplicationInfo, CustomLogger } from '../../utils';
import { isEmpty } from 'lodash';
import Toast from '../../components/atoms/toast/toast.atom';
import { FormBuildDone, FormBuildSaved } from './components/form-build-done';
import Modal from '../../components/molecules/modal.molecule';
// import Toast from '../../components/atoms/toast/toast.atom';

const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

const stageList = ['select-countries', 'basic-details', 'business-details', 'verification-details'];

const stageCompletionIndex = {
  selectCountries: 0,
  basicDetails: 1,
  businessDetails: 2,
  verificationDetails: 3,
};

const FormBuilder = () => {
  const [isActive, setActive] = useState(0);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [stagesCompleted, setStagesCompleted] = useState(0);

  const [formBuildDoneDialogOpen, setFormBuildDoneDialogOpen] = useState(false);
  const [formBuildSavedDialogOpen, setFormBuildSavedDialogOpen] = useState(false);
  const formIds: string[] = useSelector((state: RootState) => state.formConfig.form_config.map(f => f.form_id));

  const [isFormSubmissionSuccessful, setIsFormSubmissionSuccessful] = useState(false);
  const countryList: ICountryConfig[] = useSelector((state: RootState) => state.formConfig.form_config);
  const formConfig = useSelector((state: RootState) => state.formConfig);

  const dispatch = useDispatch();

  const handleSaveAndPublish = async () => {
    console.log('formIds', formIds);
    await handleSaveForm();
    for (const formId of formIds) {
      try {
        const formBuilt = await buildForm(formId);
        console.log(formBuilt.data);

        if (formBuilt.status === 200) {
          console.log('formBuilt.data', formBuilt.data);
          setIsFormSubmissionSuccessful(true);
          console.log('Form built successfully - s');

          // Toast.show({
          //   title: 'Form built successfully',
          //   destroy: () => {},
          //   type: 'success',
          // });

          setFormBuildDoneDialogOpen(true);

          // Last stage completed
          setStagesCompleted(stageCompletionIndex.verificationDetails);

          console.log('Form built successfully - e');
        } else {
          console.log('Error');
        }
      } catch (error) {
        console.error('Dropped it here', error);
        Toast.error({
          content: 'Please check if any errors in the form',
          title: 'Failed building the form',
        });
        setIsFormSubmissionSuccessful(false);
      }
    }
  };
  const handleNextClick = async (index: number) => {
    console.log('Next clicked on index', index);
    console.log('index', index);

    if (index === 0) {
      /**
       * Stage 1 : Select Countries
       */
      try {
        console.log('Upserting Countries');
        const upserted = await upsertCountries(countryList || []);
        console.log('upserted', upserted.status);
        console.log('upserted', upserted.data);

        upserted.data.data.forEach(element => {
          dispatch(setFormIdForCountry([element?.country?.country_name, element.form_id]));
        });
        setActiveTabIndex(activeTabIndex + 1 >= formSteps.length ? formSteps.length - 1 : activeTabIndex + 1);
        setStagesCompleted(stageCompletionIndex.selectCountries);
      } catch (error) {
        console.error(error);
      }
    } else if (index === 1) {
      /**
       * Stage 2 : Basic Details
       */
      try {
        const requestPayload = [];
        countryList.forEach(country => {
          const obj = {
            form_id: country.form_id,
            company_id: getCompanyId(),
            application_id: getApplicationInfo().applicationId,
            basic_details: {
              fields: [...country.fields.map(({ field_display_name, ...f }) => f)],
            },
          };
          requestPayload.push(obj);
        });

        console.log('requestPayload');
        console.log(requestPayload);
        const upserted = await upsertFormFields({
          form_schemas: requestPayload,
        });
        console.log('upserted', upserted.status);
        console.log('upserted', upserted.data);
        setActiveTabIndex(activeTabIndex + 1 >= formSteps.length ? formSteps.length - 1 : activeTabIndex + 1);
        setStagesCompleted(stageCompletionIndex.basicDetails);
      } catch (error) {
        Toast.error({
          title: 'Failed to save form',
        });
        console.error(error);
      }
    } else if (index === 2) {
      /**
       * Stage 3 : Business Details
       */
      try {
        const requestPayload = [];
        countryList.forEach(country => {
          const obj = {
            form_id: country.form_id,
            company_id: getCompanyId(),
            application_id: getApplicationInfo().applicationId,
            business_details: {
              fields: [...country.business_fields.map(({ field_display_name, ...f }) => f)],
            },
          };
          if (
            obj['business_details'].fields.map(f => f.field_name).indexOf('gst_register') !== -1 &&
            obj['business_details'].fields.map(f => f.field_name).indexOf('gst_num') !== -1
          ) {
            obj['business_details'].fields.push({
              field_name: 'gst_num',
              is_required: true,
            });
          }

          requestPayload.push(obj);
        });

        console.log('requestPayload');
        console.log(requestPayload);
        const upserted = await upsertFormFields({
          form_schemas: requestPayload,
        });
        console.log('upserted', upserted.status);
        console.log('upserted', upserted.data);
        setActiveTabIndex(activeTabIndex + 1 >= formSteps.length ? formSteps.length - 1 : activeTabIndex + 1);
        setStagesCompleted(stageCompletionIndex.businessDetails);
      } catch (error) {
        console.error(error);
      }
    }
  };
  const formSteps = [
    {
      name: 'Select Countries',
      component: SelectCountries,
      path: '/form-builder/select-countries',
    },
    {
      name: 'Basic Details',
      component: BasicDetails,
      path: '/form-builder/basic-details',
    },
    {
      name: 'Business Details',
      component: BusinessDetails,
      path: '/form-builder/business-details',
    },
    {
      name: 'Verification Details',
      component: VerificationDetails,
      path: '/form-builder/verification-details',
    },
    // {
    //   name: 'Submit Form',
    //   component: FooterConfiguration,
    //   path: '/form-builder/submit-form',
    // },
  ];

  useEffect(() => {
    setActive(activeTabIndex);
    navigate(formSteps[activeTabIndex].path);

    // Load sub components by setting them active
    formSteps.forEach((item, index) => {
      if (window.location.pathname.includes(item.path)) {
        setActive(activeTabIndex);
        // setActiveTabIndex(index);
      }
    });
  }, [activeTabIndex, isActive]);

  useEffect(() => {
    (async () => {
      console.log('Initiating getFormsForApplication');
      const forms = await getFormsForApplication();

      if (forms.status === 200) {
        const formData = forms.data.data;
        console.log('Received form data', formData);

        if (formData?.length > 0) {
          const isBasicDetailFilledAlready =
            formData.filter((f: any) => f?.basic_details?.fields?.length > 0).length > 0;
          console.log('🚀 ~ isBasicDetailFilledAlready:', isBasicDetailFilledAlready);

          if (isBasicDetailFilledAlready) {
            console.log('Basic detail filled already');
            setStagesCompleted(stageCompletionIndex.basicDetails);
          }

          const isBusinessDetailFilledAlready =
            formData.filter((f: any) => f?.business_details?.fields?.length > 0).length > 0;
          if (isBusinessDetailFilledAlready) {
            console.log('Business detail filled already');
            setStagesCompleted(stageCompletionIndex.businessDetails);
          }

          // TODO Set for verification

          const isVerificationDetailFilled =
            formData.filter((f: any) => !isEmpty(f?.document_verification) || !isEmpty(f?.rules)).length > 0;
          if (isVerificationDetailFilled) {
            console.log('Verification detail filled already');
            setStagesCompleted(stageCompletionIndex.verificationDetails);
          }
        }
      }
    })();

    return () => {};
  }, []);

  const navigate = useNavigate();
  const handleMenuChange = (index: number) => {
    setActive(index);
    navigate(formSteps[index].path);
    setActiveTabIndex(index);
    // setStagesCompleted(index);

    // (async () => {
    //   console.log('handleMenuChange: Initiating getFormsForApplication');
    //   const forms = await getFormsForApplication();

    //   if (forms.status === 200) {
    //     const formData = forms.data.data;
    //     console.log('Received form data', formData);
    //     if (formData?.length > 0) {
    //       const isBasicDetailFilledAlready =
    //         formData.filter((f: any) => f?.basic_details?.fields?.length > 0).length > 0;
    //       console.log('🚀 ~ isBasicDetailFilledAlready:', isBasicDetailFilledAlready);
    //       if (isBasicDetailFilledAlready) {
    //         console.log('Basic detail filled already');
    //         setStagesCompleted(1);
    //       }
    //       const isBusinessDetailFilledAlready =
    //         formData.filter((f: any) => f?.business_details?.fields?.length > 0).length > 0;
    //       if (isBusinessDetailFilledAlready) {
    //         console.log('Business detail filled already');
    //         setStagesCompleted(2);
    //       }
    //     }
    //   }
    // })();

    console.log('----');
    console.log(index);
    console.log(formSteps[index].path);
    console.log('----');
  };

  const handleSaveForm = async () => {
    try {
      const requestPayload = [];
      countryList.forEach(country => {
        const obj = {
          form_id: country.form_id,
          company_id: getCompanyId(),
          application_id: getApplicationInfo().applicationId,
          document_verification: {},
        };

        if (country?.verification_documents?.proof_of_address?.length > 0)
          obj['document_verification']['proof_of_address'] = country.verification_documents.proof_of_address;
        if (country.verification_documents?.proof_of_identity?.length > 0)
          obj['document_verification']['proof_of_identity'] = country.verification_documents.proof_of_identity;
        if (country.verification_documents?.proof_of_business?.length > 0)
          obj['document_verification']['proof_of_business'] = country.verification_documents.proof_of_business;

        if (!isEmpty(country.rules)) {
          obj['rules'] = country.rules;
        }
        requestPayload.push(obj);
      });

      console.log('requestPayload');
      console.log(requestPayload);
      const upserted = await upsertFormFields({
        form_schemas: requestPayload,
      });

      return upserted.status === 200;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <div className={css('merchant-profile-container')}>
      <div className={css('container-head')}>
        <div className={css('container-head-title')}>
          <div className={css('title')}>Form Builder</div>
          <p className={css('subtitle')}>Configure registration form to be served on store-front</p>
        </div>
        <div className={css('d-flex-center options gap-2 font-small')}>
          <div className={activeTabIndex !== 0 && css('color-blue')} onClick={() => {}}>
            <span
              className={activeTabIndex !== 0 ? css('hover-cursor-pointer') : css('hover-cursor-not-allowed')}
              onClick={() => {
                setActiveTabIndex(activeTabIndex - 1 < 0 ? 0 : activeTabIndex - 1);
                // setStagesCompleted(stagesCompleted - 1 < 0 ? 0 : stagesCompleted - 1);
              }}
            >
              Previous
            </span>
          </div>
          <span className={css('color-light-gray')}>|</span>
          <div className={activeTabIndex !== formSteps.length - 1 && css('color-blue')}>
            {activeTabIndex < stageCompletionIndex.verificationDetails && (
              <span onClick={() => handleNextClick(activeTabIndex)}>
                <Button>Save and Proceed</Button>
              </span>
            )}

            {activeTabIndex >= stageCompletionIndex.verificationDetails && (
              <div className={css('d-flex gap-1')}>
                <span
                  onClick={async () => {
                    const done = await handleSaveForm();

                    if (done) {
                      Toast.success({
                        title: 'Form saved as Draft',
                        content: 'You can continue editing the form',
                      });
                    } else {
                      Toast.error({
                        title: 'Failed to save form',
                      });
                    }
                  }}
                >
                  <Button theme="secondary">Save</Button>
                </span>
                <span onClick={() => handleSaveAndPublish()}>
                  <Button>Save & Publish</Button>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={css('form-container')}>
        <div className={css('border-grey form-steps-container')}>
          <div className={css('form-steps d-flex-column')}>
            {formSteps
              .filter(fs => fs.name !== 'FORM_BUILD_COMPLETED')
              .map((item, index) => (
                <div
                  className={css(
                    `d-flex gap-1 form-step-item feature-menu-item ${isActive === index ? 'active' : ''} ${
                      stagesCompleted < index ? 'hover-cursor-not-allowed color-gray' : ''
                    }`
                  )}
                  key={item.name}
                  onClick={() => handleMenuChange(index)}
                >
                  {stagesCompleted >= index && activeTabIndex !== index && (
                    <div className={css(`form-step-icon`)}>
                      <SvgIcConfirm />
                    </div>
                  )}
                  {activeTabIndex === index && (
                    <div className={css(`form-step-icon`)}>
                      <SvgIcEditPen />
                    </div>
                  )}
                  {stagesCompleted < index && activeTabIndex !== index && (
                    <div className={css(`form-step-number`)}>{index + 1}</div>
                  )}

                  <div>{item.name}</div>
                </div>
              ))}
          </div>
        </div>
        <div className={css('form-fields-container w-100')}>
          <Outlet />
        </div>

        <Modal
          children={
            <>
              <FormBuildDone />
            </>
          }
          handleCloseModal={() => {
            setFormBuildDoneDialogOpen(false);
          }}
          id="form-build-done"
          kind="informational"
          showModal={formBuildDoneDialogOpen}
          title=""
          isClosable={true}
        ></Modal>

        <Modal
          children={
            <>
              <FormBuildSaved />
            </>
          }
          handleCloseModal={() => {
            setFormBuildSavedDialogOpen(false);
          }}
          id="form-build-done"
          kind="informational"
          showModal={formBuildSavedDialogOpen}
          title=""
          isClosable={true}
        ></Modal>
      </div>
    </div>
  );
};
export default FormBuilder;
