import { useEffect, useState } from 'react';
import CSS from './verification-details.module.scss';
import {
  Checkbox,
  Dropdown,
  Input,
  SvgIcChevronDown,
  SvgIcChevronUp,
  SvgIcClose,
  SvgIcTrash,
} from '@gofynd/nitrozen-react';
// import IndiaFlag from '../../../assets/flag-png-india.png';
import { ReactComponent as FlagIN } from '../../../assets/flag-in.svg';
import { ReactComponent as FlagUS } from '../../../assets/flag-us.svg';
import { ReactComponent as FlagAE } from '../../../assets/flag-ae.svg';
import { ReactComponent as FlagSA } from '../../../assets/flag-sa.svg';
import { ReactComponent as IconUploadSvg } from '../../../assets/icon-upload.svg';
import { ReactComponent as TextInputSvg } from '../../../assets/text-input.svg';
import { ReactComponent as DraggableSvg } from '../../../assets/draggable.svg';
import { ReactComponent as IconPlus } from '../../../assets/plus-icon.svg';
import {
  ICountryConfig,
  IDocument,
  IDocumentVerification,
  IRule,
  IRules,
  setRulesForCountry,
  setVerificationDocumentsForCategoryForCountry,
  setVerificationDocumentsForCountry,
} from '../../../store/slices/form-builder.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getCompiledClasses } from '../../../services/style.service';
import Accordion from '../../../components/atoms/accordion/accordion.atom';
import RadioInput from '../../../components/atoms/radio/radio.atom';

import isEmpty from 'lodash/isEmpty';

import { getFormsForApplication, getVerificationDetailFields } from '../form-builder.service';
import InputTextAtom from '../../../components/atoms/input-text/input-text.atom';

import { Rule, RuleSeparator } from './rule.component';

import Loader from 'components/atoms/loader/loader.atom';

const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

const categoryToFieldName = {
  POA: 'proof_of_address',
  POB: 'proof_of_business',
  POI: 'proof_of_identity',
};

export const COUNTRY_FLAGS = {
  India: <FlagIN></FlagIN>,
  'United States of America': <FlagUS></FlagUS>,
  'United Arab Emirates': <FlagAE></FlagAE>,
  'The Kingdom of Saudi Arabia': <FlagSA></FlagSA>,
};

const ruleDocuments = ['POA', 'POI', 'POB'];

export const dropdownLabelMap = {
  POA: 'Proof of Address (POA)',
  POI: 'Proof of Identity (POI)',
  POB: 'Proof of Business (POB)',
};
export const dropdownItems = ruleDocuments.map(r => ({
  text: dropdownLabelMap[r],
  value: r,
}));

export const AddRuleIcon = () => {
  return (
    <>
      <div className={css('rule-separator-tail')}>
        <span className={css('rule-add')}>
          <IconPlus></IconPlus>
        </span>
      </div>
    </>
  );
};

const docToTitleMap = {
  aadhaar_card: 'Aadhar Card',
  bank_statement: 'Bank Statement',
  driving_license: 'Driving License',
  municipal_corporation_receipt: 'Municipal Corporation Receipt',
  passport: 'Passport',
  property_tax_receipt: 'Property Tax Receipt',
  registered_lease_deed: 'Registered Lease Deed',
  rental_agreement: 'Rental Agreement',
  utility_bills: 'Utility Bills',
  voter_id_card: 'Voter ID Card',
  business_bank_statement: 'Business Bank Statement',
  business_insurance_policy: 'Business Insurance Policy',
  business_license: 'Business License',
  business_registration_certificate: 'Business Registration Certificate',
  current_account_cheque: 'Current Account Cheque',
  enlistment_certificate: 'Enlistment Certificate',
  financial_statements: 'Financial Statements',
  fssai_registration: 'FSSAI Registration',
  gram_panchayat_certificate: 'Gram Panchayat Certificate',
  gst_registration_certificate: 'GST Registration Certificate',
  gumasta_license: 'Gumasta License',
  incorporation_documents: 'Incorporation Documents',
  labour_certificate: 'Labour Certificate',
  lease_agreement: 'Lease Agreement',
  moa_aoa: 'MOA/AOA',
  municipal_corporation_certificate: 'Municipal Corporation Certificate',
  partnership_deed: 'Partnership Deed',
  sales_invoices: 'Sales Invoices',
  shop_and_establishment_certificate: 'Shop and Establishment Certificate',
  shop_enrollment_certificate: 'Shop Enrollment Certificate',
  trade_license: 'Trade License',
  vendor_registration_certificate: 'Vendor Registration Certificate',
  pan_card: 'PAN Card',
};

const DocumentPreviewConfig = ({
  item,
  index,
  setDocumentPreviewConfigData,
  documentCategory,
  country,
}: {
  item: IDocument;
  index: number;
  setDocumentPreviewConfigData: any;
  documentCategory: string;
  country: ICountryConfig;
}) => {
  const uniqueIdentifier = (
    country.country_alias +
    '.' +
    documentCategory +
    '.' +
    item.document_name +
    '.' +
    index
  ).toLowerCase();
  const [radioSelected, setRadioSelected] = useState('front.' + uniqueIdentifier);

  const [isAuto, setIsAuto] = useState<boolean>(item.is_auto || true);
  const [isManual, setIsManual] = useState<boolean>(item.is_manual || true);
  const [autoTitle, setAutoTitle] = useState<string>(item.document_display_name_auto || item.document_display_name);
  const [manualTitle, setManualTitle] = useState<string>(item.document_display_name || item.document_display_name_auto);

  const [previewConfigObj, setPreviewConfigObj] = useState({});

  const docs = useSelector(
    (state: RootState) =>
      state.formConfig.form_config.find(f => f.country_alias === country.country_alias).verification_documents[
        documentCategory
      ]
  );

  console.log('DocumentPreviewConfig docs', docs);
  useEffect(() => {
    console.log('setPreviewConfigObj from child - start');

    setPreviewConfigObj({
      ...previewConfigObj,
      document_category: documentCategory,
      document_name: item.document_name,
      is_auto: isAuto,
      is_manual: isManual,
      document_display_name: manualTitle,
      document_display_name_auto: autoTitle || manualTitle,
      document_images: radioSelected.split('.')[0] === 'front-back' ? ['front', 'back'] : [radioSelected.split('.')[0]],
    });

    const objectToSet = {
      document_name: item.document_name,
      is_auto: isAuto,
      is_manual: isManual,
      document_display_name: manualTitle,
      document_display_name_auto: autoTitle || manualTitle,
      document_images: radioSelected.split('.')[0] === 'front-back' ? ['front', 'back'] : [radioSelected.split('.')[0]],
    };

    const oldDocs = [...docs];

    const objToEdit = oldDocs.find(d => d.document_name === item.document_name);

    if (!isEmpty(objToEdit)) {
      const index = oldDocs.indexOf(objToEdit);
      oldDocs[index] = objectToSet;
    }

    dispatch(setVerificationDocumentsForCategoryForCountry([country.country_name, documentCategory, oldDocs]));

    console.log('setPreviewConfigObj from child - end');

    return () => {};
  }, [isAuto, isManual, autoTitle, manualTitle, radioSelected]);

  const dispatch = useDispatch();
  return (
    <div className={css(`pad-edge-1 gap-1 d-flex cancel-div}`)} key={index}>
      <div className={css('document-card d-flex-column')}>
        <div className={css('document-card-title d-flex gap-1')}>
          <span>
            <DraggableSvg></DraggableSvg>
          </span>
          <span>{docToTitleMap[item.document_name]}</span>
        </div>

        {item.is_auto && (
          <>
            <div className={css('margin-edge-2')}>
              <Checkbox
                checkboxValue={isAuto}
                id="autoApproval"
                labelText="Auto Approval"
                name="basic"
                onChange={event => {
                  console.log('event :', event);
                  setIsAuto(event);
                }}
                onIconClick={function noRefCheck() {}}
                stateMessage="Your Validation message!"
                value="autoApproval"
              />
            </div>
            <div className={css('d-flex-column pad-2 input-card margin-edge-1')}>
              <span className={css('d-flex gap-1')}>
                <TextInputSvg></TextInputSvg>
                <span className={css('font-size-12')}>Text / Numeric Input</span>
              </span>
              <div className={css('margin-edge-2')}>
                <InputTextAtom
                  onChange={e => {
                    setAutoTitle(e);
                  }}
                  placeholder={autoTitle}
                  value={autoTitle}
                ></InputTextAtom>
              </div>
            </div>
          </>
        )}

        {item.is_manual && (
          <>
            <div className={css('margin-edge-2')}>
              <Checkbox
                disabled
                checkboxValue={isManual}
                id="manualApproval"
                labelText="Manual Approval"
                name="basic"
                onChange={() => {}}
                onIconClick={function noRefCheck() {}}
                stateMessage="Your Validation message!"
                value="manualApproval"
              />
            </div>
            <div className={css('d-flex-column pad-2 input-card margin-edge-1')}>
              <span className={css('d-flex gap-1')}>
                <IconUploadSvg></IconUploadSvg>
                <span>Image Uploader</span>
              </span>
              <div className={css('d-flex-column gap-1 margin-top-2 fs-12')}>
                <RadioInput
                  id={'front.' + uniqueIdentifier}
                  labelText="Front Image"
                  name={'proof-uploader-type.' + uniqueIdentifier}
                  onChange={e => setRadioSelected('front.' + uniqueIdentifier)}
                  radioValue={'front.' + uniqueIdentifier}
                  value={radioSelected}
                />
                <RadioInput
                  id={'front-back.' + uniqueIdentifier}
                  labelText="Front & Back Image"
                  name={'proof-uploader-type.' + uniqueIdentifier}
                  onChange={e => setRadioSelected('front-back.' + uniqueIdentifier)}
                  radioValue={'front-back.' + uniqueIdentifier}
                  value={radioSelected}
                />
                <RadioInput
                  id={'proof_doc.' + uniqueIdentifier}
                  labelText="Proof File / PDF"
                  name={'proof-uploader-type.' + uniqueIdentifier}
                  onChange={e => setRadioSelected('proof_doc.' + uniqueIdentifier)}
                  radioValue={'proof_doc.' + uniqueIdentifier}
                  value={radioSelected}
                />
              </div>

              <div className={css('margin-edge-2')}>
                <InputTextAtom
                  onChange={e => {
                    setManualTitle(e);
                  }}
                  placeholder={manualTitle}
                  value={manualTitle}
                ></InputTextAtom>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const AccordionHeader = ({ country }) => {
  return (
    <div className={css('d-flex-center gap-1 pad-1')}>
      <div className={css('flag-container')}>{COUNTRY_FLAGS[country.country_name]}</div>
      <span> {country.country_name} </span>
    </div>
  );
};

const RuleContainer = ({ country, totalDocsSelected }: { country: ICountryConfig; totalDocsSelected: any }) => {
  const dispatch = useDispatch();

  const [showRules, setShowRules] = useState(true);

  // the number, when at one document is selected, category is considered selected
  const [nCategoriesSelected, setNCategoriesSelected] = useState(0);

  // list of categories selected in all rules. e.g. ['POA', 'POB']
  const [totalCategoriesSelectedInRules, setTotalCategoriesSelectedInRules] = useState([]);

  const [ruleSeparationOperator, setRuleSeparationOperator] = useState('AND');
  const [ruleConfig1, setRuleConfig1] = useState<IRule>({});
  const [ruleConfig2, setRuleConfig2] = useState<IRule>({});

  const [addRuleEnabled, setAddRuleEnabled] = useState(false);
  const [addSubRuleEnabled, setAddSubRuleEnabled] = useState(false);

  const [conditions1, setRuleConditions1] = useState([]);
  const [conditions2, setRuleConditions2] = useState([]);
  const [totalRules, setTotalRules] = useState(1);

  const [ruleConfig, setRuleConfig] = useState({});

  const selectedRulesAlready = useSelector((state: RootState) =>
    state.formConfig.form_config.find(c => c.country_alias === country.country_alias)
  ).rules;

  useEffect(() => {
    console.log('RULES:: nCategoriesSelected', nCategoriesSelected);
    return () => {};
  }, [nCategoriesSelected]);

  useEffect(() => {
    console.log('RULES:: totalDocsSelected', totalDocsSelected);

    let nCategoriesSelected = 0;
    if (totalDocsSelected?.POA > 0) {
      console.log("totalDocsSelected['POA']", totalDocsSelected['POA']);
      nCategoriesSelected++;
    }
    if (totalDocsSelected?.POB > 0) {
      console.log("totalDocsSelected['POB']", totalDocsSelected['POB']);
      nCategoriesSelected++;
    }
    if (totalDocsSelected?.POI > 0) {
      console.log("totalDocsSelected['POI']", totalDocsSelected['POI']);
      nCategoriesSelected++;
    }

    console.log('RULES::: ~ nCategoriesSelected:', nCategoriesSelected);
    if (nCategoriesSelected === 1) {
      setAddRuleEnabled(false);
      setAddSubRuleEnabled(false);
      setNCategoriesSelected(1);
    } else if (nCategoriesSelected === 2) {
      setAddRuleEnabled(false);
      setAddSubRuleEnabled(true);
      setNCategoriesSelected(2);

      if (isEmpty(selectedRulesAlready['rule#1'])) {
        const conditions = [];
        if (totalDocsSelected?.POA > 0) conditions.push('POA');
        if (totalDocsSelected?.POB > 0) conditions.push('POB');
        if (totalDocsSelected?.POI > 0) conditions.push('POI');
        setRuleConfig1({ conditions, operator: 'AND' });
      }

      // When 1 of 3 is deleted, second rule should be removed

      if (!isEmpty(selectedRulesAlready['rule#2'])) {
        setRuleConfig2({});
      }
    } else if (nCategoriesSelected === 3) {
      setAddRuleEnabled(true);
      setAddSubRuleEnabled(true);
      setNCategoriesSelected(3);
    } else {
      setNCategoriesSelected(0);
    }
    return () => {};
  }, [totalDocsSelected]);

  useEffect(() => {
    console.log('ruleConfig1 :: :: ', ruleConfig1);
    console.log('ruleSeparationOperator :: ::', ruleSeparationOperator);
    console.log('ruleConfig2 :: :: ', ruleConfig2);

    const ruleConfigCopy = {};

    if (!isEmpty(ruleConfig1)) {
      ruleConfigCopy['rule#1'] = ruleConfig1;
    }
    if (!isEmpty(ruleConfig2)) {
      ruleConfigCopy['rule#2'] = ruleConfig2;
    }
    if (!isEmpty(ruleConfig1) && !isEmpty(ruleConfig2)) {
      ruleConfigCopy['parent_conditions'] = {
        conditions: ['rule#1', 'rule#2'],
        operator: ruleSeparationOperator,
      };
    }

    dispatch(setRulesForCountry([country.country_name, { ...ruleConfigCopy }]));

    console.log('RULES:: ruleConfigCopy :: ::', ruleConfigCopy);

    const r1 = selectedRulesAlready['rule#1']?.conditions || [];
    const r2 = selectedRulesAlready['rule#2']?.conditions || [];

    console.log('RULES:: r1', r1);
    console.log('RULES:: r2', r2);

    const totalFieldsSelected = [];

    if (!isEmpty(ruleConfig1)) {
      for (const field of ruleConfig1?.conditions) {
        // if (!totalFieldsSelected.includes(field))
        field && totalFieldsSelected.push(field);
      }
    }
    if (!isEmpty(ruleConfig2)) {
      for (const field of ruleConfig2?.conditions) {
        // if (!totalFieldsSelected.includes(field))
        field && totalFieldsSelected.push(field);
      }
    }
    setTotalCategoriesSelectedInRules(totalFieldsSelected);
    console.log('RULES:: ~ useEffect ~ totalFieldsSelected:', totalFieldsSelected);

    return () => {};
  }, [ruleConfig1, ruleConfig2, ruleSeparationOperator]);

  useEffect(() => {
    console.log('setting ', selectedRulesAlready);

    if (selectedRulesAlready?.parent_conditions?.operator) {
      setRuleSeparationOperator(selectedRulesAlready?.parent_conditions?.operator);
    }

    if (selectedRulesAlready['rule#1']?.conditions?.length + selectedRulesAlready['rule#2']?.conditions?.length === 3) {
      setAddSubRuleEnabled(false);
    }

    if (!isEmpty(selectedRulesAlready['rule#2']) && isEmpty(selectedRulesAlready['rule#2'].conditions)) {
      setRuleConfig2({});
    }

    return () => {};
  }, [selectedRulesAlready]);
  return (
    <>
      {!showRules && (
        <div
          className={css('clickable-text color-blue pad-1 fs-14')}
          onClick={() => {
            // setRuleConfig1({
            //   conditions: ['POB'],
            //   operator: 'AND',
            // });
            setShowRules(true);
          }}
        >
          + Rules
        </div>
      )}

      {showRules && (
        <>
          {nCategoriesSelected === 0 && (
            <div className={css('pad-2 color-red')}>Please select required documents to add rules</div>
          )}
          {nCategoriesSelected === 1 && (
            <div className={css('pad-2 color-blue')}>
              You need to select more than one category of documents in order to be able to configure rules
            </div>
          )}
          {nCategoriesSelected > 1 && (
            <Accordion
              header={<div className={css('bold padding-bottom-2 fs-14')}>Rules</div>}
              children={
                <>
                  <div className={css('pad-1 rule-container')}>
                    {!isEmpty(selectedRulesAlready['rule#1']) && (
                      <Rule
                        country={country}
                        allRules={selectedRulesAlready}
                        addSubRuleEnabled={addSubRuleEnabled}
                        setAddSubRuleEnabled={setAddSubRuleEnabled}
                        conditions={selectedRulesAlready['rule#1']?.conditions}
                        index={1}
                        setRuleConfig={setRuleConfig1}
                        deletable={false}
                        totalRules={totalRules}
                        setTotalRules={setTotalRules}
                        totalDocsSelected={totalDocsSelected}
                        internalRuleOperator={selectedRulesAlready['rule#1']?.operator}
                        // setSelectedRuleDocuments={setSelectedRuleDocuments}
                        totalCategoriesSelectedInRules={totalCategoriesSelectedInRules}
                      />
                    )}
                  </div>

                  {/* Load already existing second rule */}
                  {!isEmpty(selectedRulesAlready['rule#2']) && (
                    <>
                      <RuleSeparator
                        setRuleSeparationOperator={setRuleSeparationOperator}
                        operator={ruleSeparationOperator}
                      />
                      <div className={css('pad-1 rule-container')}>
                        <Rule
                          country={country}
                          allRules={selectedRulesAlready}
                          addSubRuleEnabled={addSubRuleEnabled}
                          setAddSubRuleEnabled={setAddSubRuleEnabled}
                          conditions={selectedRulesAlready['rule#2']?.conditions}
                          index={2}
                          setRuleConfig={setRuleConfig2}
                          totalRules={totalRules}
                          setTotalRules={setTotalRules}
                          totalDocsSelected={totalDocsSelected}
                          totalCategoriesSelectedInRules={totalCategoriesSelectedInRules}
                          // setSelectedRuleDocuments={setSelectedRuleDocuments}
                        />
                      </div>
                    </>
                  )}

                  {/* Add Rule Button */}
                  {isEmpty(selectedRulesAlready['rule#2']) && addRuleEnabled && (
                    <div
                      onClick={() => {
                        setRuleConfig2({
                          conditions: [
                            ...dropdownItems
                              .filter(item => totalDocsSelected[item.value] > 0)
                              .map(i => i.value)
                              .filter(i => !selectedRulesAlready['rule#1'].conditions.includes(i)),
                          ],
                          operator: 'AND',
                        });
                        setTotalRules(totalRules + 1);
                      }}
                    >
                      <AddRuleIcon />
                    </div>
                  )}
                </>
              }
              isOpen={true}
              hasBorder={false}
            />
          )}
        </>
      )}
    </>
  );
};
const CategoryWiseDocuments = ({
  country,
  documentListCategoryWise,
}: {
  country: ICountryConfig;
  documentListCategoryWise: IDocumentVerification;
}) => {
  const [showRules, setShowRules] = useState(false);
  const [allAccordionsOpen, setAllAccordionsOpen] = useState(true);

  const [selectedRuleDocuments, setSelectedRuleDocuments] = useState([]);

  const docsSelected = useSelector((state: RootState) =>
    state.formConfig.form_config.find(c => c.country_alias === country.country_alias)
  )?.verification_documents;

  const totalDocsSelected = {
    POA: docsSelected?.proof_of_address?.length || 0,
    POB: docsSelected?.proof_of_business?.length || 0,
    POI: docsSelected?.proof_of_identity?.length || 0,
  };

  const [documentsCategoryWise, setDocumentsCategoryWise] = useState<IDocumentVerification>({});

  // Rules

  const dispatch = useDispatch();

  useEffect(() => {
    console.log(`documentListCategoryWise for ${country.country_name}: `, documentsCategoryWise);
    setDocumentsCategoryWise(documentListCategoryWise);
    return () => {};
  }, [documentListCategoryWise]);

  useEffect(() => {
    console.log('selectedRuleDocuments', selectedRuleDocuments);

    return () => {};
  }, [selectedRuleDocuments]);

  useEffect(() => {
    (async () => {
      const formsForApplication = await getFormsForApplication();
      console.log('🚀 ~ formsForApplication:', formsForApplication);

      const countrySelected = formsForApplication.data?.data.find(
        c => c.country.country_alias === country.country_alias
      );

      const countryVerificationDocs = { ...countrySelected?.document_verification };

      if (!isEmpty(countryVerificationDocs)) {
        if (!isEmpty(countryVerificationDocs?.proof_of_address)) {
          const obj = {
            proof_of_address: countryVerificationDocs?.proof_of_address,
            proof_of_business: countryVerificationDocs?.proof_of_business,
            proof_of_identity: countryVerificationDocs?.proof_of_identity,
          };

          if (!isEmpty(countryVerificationDocs?.proof_of_address)) {
            obj['proof_of_address'] = countryVerificationDocs?.proof_of_address;
          }

          if (!isEmpty(countryVerificationDocs?.proof_of_business)) {
            obj['proof_of_business'] = countryVerificationDocs?.proof_of_business;
          }

          if (!isEmpty(countryVerificationDocs?.proof_of_identity)) {
            obj['proof_of_identity'] = countryVerificationDocs?.proof_of_identity;
          }

          dispatch(
            setVerificationDocumentsForCountry([
              country.country_name,
              {
                ...obj,
              },
            ])
          );
        }
      }

      const rules = countrySelected?.rules;
      console.log('🚀 ~ rules:', rules);

      if (!isEmpty(rules)) {
        dispatch(setRulesForCountry([country.country_name, { ...rules }]));
      }
    })();

    return () => {};
  }, []);

  return (
    <div className={css('d-flex-column gap-1 pad-edge-1')}>
      {!isEmpty(documentsCategoryWise) && (
        <>
          <Accordion
            hasBorder={false}
            header={
              <div className={css('d-flex-center gap-2')}>
                <div className={css('bold fs-14')}>Proof of Address (POA)</div>
                <div className={css('fs-12 color-gray')}>{totalDocsSelected.POA} selected</div>
              </div>
            }
            isOpen={allAccordionsOpen}
            children={
              <SelectVerificationDocuments
                documentCategory={'POA'}
                documentList={documentsCategoryWise.proof_of_address}
                country={country}
              />
            }
          />
          <Accordion
            hasBorder={false}
            header={
              <div className={css('d-flex-center gap-2')}>
                <div className={css('bold fs-14')}>Proof of Business (POB)</div>
                <div className={css('fs-12 color-gray')}>{totalDocsSelected.POB} selected</div>
              </div>
            }
            isOpen={allAccordionsOpen}
            children={
              <SelectVerificationDocuments
                documentCategory={'POB'}
                country={country}
                documentList={documentsCategoryWise.proof_of_business}
              />
            }
          />
          <Accordion
            hasBorder={false}
            header={
              <div className={css('d-flex-center gap-2')}>
                <div className={css('bold fs-14')}>Proof of Identity (POI)</div>
                <div className={css('fs-12 color-gray')}>{totalDocsSelected.POI} selected</div>
              </div>
            }
            isOpen={allAccordionsOpen}
            children={
              <SelectVerificationDocuments
                documentCategory={'POI'}
                country={country}
                documentList={documentsCategoryWise.proof_of_identity}
              />
            }
          />
        </>
      )}

      <RuleContainer country={country} totalDocsSelected={totalDocsSelected}></RuleContainer>
    </div>
  );
};

const SelectVerificationDocuments = ({
  documentCategory,
  country,
  documentList,
}: {
  documentCategory: string;
  country: ICountryConfig;
  documentList: IDocument[];
}) => {
  const dispatch = useDispatch();

  const [documentListState, setDocumentListState] = useState<IDocument[]>([]);

  const keyName = categoryToFieldName[documentCategory];
  const countryLevelDocs: IDocumentVerification = useSelector(
    (state: RootState) =>
      state.formConfig.form_config.find(f => f.country_alias === country.country_alias).verification_documents
  );

  useEffect(() => {
    console.log(`documentList per category ${documentCategory}`, documentList);

    return () => {};
  }, [documentList, documentCategory]);

  // const [initDocs, setInitDocs] = useState<IDocument[]>([]);

  const [documentPreviewConfigData, setDocumentPreviewConfigData] = useState<any>({});

  useEffect(() => {
    if (!isEmpty(documentPreviewConfigData)) {
      console.log('documentPreviewConfigData from parent: ', documentPreviewConfigData);

      console.log('categoryToFieldName[documentPreviewConfigData?.document_category]');
      console.log(documentPreviewConfigData?.document_category);
      const oldDocuments: IDocument[] = countryLevelDocs[documentPreviewConfigData?.document_category];
      console.log('🚀 ~ useEffect ~ oldDocuments:', oldDocuments);

      const toUpdate = { ...oldDocuments.find(d => d.document_name === documentPreviewConfigData?.document_name) };
      console.log('🚀 ~ useEffect ~ toUpdate:', toUpdate);

      if (!isEmpty(toUpdate)) {
        toUpdate.document_images = [...documentPreviewConfigData?.document_images];
        toUpdate.document_display_name = documentPreviewConfigData?.document_display_name;
        toUpdate.document_display_name_auto =
          documentPreviewConfigData?.document_display_name_auto || documentPreviewConfigData?.document_display_name;
        toUpdate.is_auto = documentPreviewConfigData?.is_auto;
        toUpdate.is_manual = documentPreviewConfigData?.is_manual;
      }
    }

    return () => {};
  }, [documentPreviewConfigData]);

  return (
    <>
      <div className={css('business-detail-container')}>
        {documentList.length > 0 && (
          <>
            <Dropdown
              addOption={false}
              className={css('margin-edge-1')}
              id="verification-fields"
              enableSelectAll={true}
              items={[...documentList.map(f => ({ text: f.document_display_name, value: f.document_name }))]}
              value={countryLevelDocs[keyName] && countryLevelDocs[keyName].map(d => d.document_name)}
              // value={country.verification_documents[keyName].map(d => d.document_name)}
              label="Select Verification Details"
              multiple
              onChange={event => {
                const arr: IDocument[] = [];
                event.forEach(element => {
                  arr.push(documentList.find(d => d.document_name === element));
                });

                console.log('documentCategory ::::', documentCategory, keyName, arr.length);
                dispatch(
                  setVerificationDocumentsForCountry([
                    country.country_name,
                    {
                      ...countryLevelDocs,
                      [keyName]: arr,
                    },
                  ])
                );
              }}
              placeholder="Select Verification Fields"
              searchable={true}
            />

            <div className={css('d-flex-column card-container gap-1')}>
              {country.verification_documents[categoryToFieldName[documentCategory]] &&
                country.verification_documents[categoryToFieldName[documentCategory]].map((item, index) => (
                  <DocumentPreviewConfig
                    item={item}
                    index={index}
                    country={country}
                    documentCategory={categoryToFieldName[documentCategory]}
                    setDocumentPreviewConfigData={setDocumentPreviewConfigData}
                  ></DocumentPreviewConfig>
                ))}
            </div>
          </>
        )}

        {documentList.length <= 0 && (
          <div className={css('color-red fs-12 margin-edge-2')}>
            No verification fields configured of this category for this country. Please contact administrator.
          </div>
        )}
      </div>
    </>
  );
};

export const VerificationDetails = () => {
  const countryList: ICountryConfig[] = useSelector((state: RootState) => state.formConfig.form_config);

  const [verificationDocuments, setVerificationDocuments] = useState<IDocumentVerification[]>([]);

  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);

      const verificationDocArray = [];
      for (const country of countryList) {
        const verificationDocs = await getVerificationDetailFields(country.country_alias);
        console.log('verificationDocs', verificationDocs.data?.data);

        const { proof_of_address: POADocs }: { proof_of_address: IDocument[] } = verificationDocs.data?.data;
        const { proof_of_identity: POIDocs }: { proof_of_identity: IDocument[] } = verificationDocs.data?.data;
        const { proof_of_business: POBDocs }: { proof_of_business: IDocument[] } = verificationDocs.data?.data;

        const documentVerificationObj: IDocumentVerification = {
          proof_of_address: POADocs.map(d => {
            const {
              document_name,
              document_display_name,
              document_display_name_auto,
              is_manual,
              is_auto,
              document_images,
            } = d;
            return {
              document_name,
              document_display_name,
              document_display_name_auto: document_display_name_auto || document_display_name,
              is_manual,
              is_auto,
              document_images,
            };
          }),
          proof_of_identity: POIDocs.map(d => {
            const {
              document_name,
              document_display_name,
              document_display_name_auto,
              is_manual,
              is_auto,
              document_images,
            } = d;
            return {
              document_name,
              document_display_name,
              document_display_name_auto: document_display_name_auto || document_display_name,
              is_manual,
              is_auto,
              document_images,
            };
          }),
          proof_of_business: POBDocs.map(d => {
            const {
              document_name,
              document_display_name,
              document_display_name_auto,
              is_manual,
              is_auto,
              document_images,
            } = d;
            return {
              document_name,
              document_display_name,
              document_display_name_auto: document_display_name_auto || document_display_name,
              is_manual,
              is_auto,
              document_images,
            };
          }),
        };

        verificationDocArray.push(documentVerificationObj);
      }

      console.log('[...verificationDocArray]');
      console.log([...verificationDocArray]);
      setVerificationDocuments([...verificationDocArray]);

      setIsDataLoading(false);
    })();

    console.log('country.verification_documents');
    console.log('verificationDocuments', verificationDocuments);
  }, []);

  return (
    <div className={css('d-flex-column gap-2')}>
      {countryList.length === 0 && <span>Please select countries first</span>}

      {isDataLoading && <Loader />}

      {!isDataLoading && countryList.length > 0 && (
        <>
          {countryList.map((country, index) => {
            return (
              <>
                <Accordion
                  header={<AccordionHeader country={country} />}
                  isOpen={true}
                  key={index}
                  children={
                    <CategoryWiseDocuments country={country} documentListCategoryWise={verificationDocuments[index]} />
                  }
                ></Accordion>
              </>
            );
          })}
        </>
      )}
    </div>
  );
};

export default VerificationDetails;
