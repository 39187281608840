import InputTextAtom from '../../../components/atoms/input-text/input-text.atom';
import SelectAtom from '../../../components/atoms/select/select.atom';
import { KYC_STATUS_DROPDOWN } from '../services/merchant-profile.service';
import CSS from './export-history.component.module.scss';
import '../../../assets/csv-icon.svg';
import { ReactComponent as CSVIconSvg } from '../../../assets/csv-icon.svg';
import { Badge, Pagination } from '@gofynd/nitrozen-react';
import { getCompiledClasses } from '../../../services/style.service';
import { useState } from 'react';

const exportHistoryData = [
  {
    fileName: 'Department 2022-11-10 09:50:05',
    user: {
      name: 'Siddharth Mehta',
    },
    date: 'Nov 10, 2022 11:50:43 AM',
    status: 'VERIFIED',
  },
  {
    fileName: 'Department 2022-11-11 08:30:22',
    user: {
      name: 'Anika Sharma',
    },
    date: 'Nov 11, 2022 10:30:22 AM',
    status: 'PENDING',
  },
  {
    fileName: 'Department 2022-11-12 07:20:14',
    user: {
      name: 'Ravi Patel',
    },
    date: 'Nov 12, 2022 09:20:14 AM',
    status: 'VERIFIED',
  },
  {
    fileName: 'Department 2022-11-13 06:15:37',
    user: {
      name: 'Neha Gupta',
    },
    date: 'Nov 13, 2022 08:15:37 AM',
    status: 'REJECTED',
  },
  {
    fileName: 'Department 2022-11-14 05:45:21',
    user: {
      name: 'Karan Singh',
    },
    date: 'Nov 14, 2022 07:45:21 AM',
    status: 'PENDING',
  },
];
const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

enum TypeEnum {
  TYPE_DEFAULT = 'default',
  TYPE_TOP = 'top',
}
const ExportHistory = () => {
  const handlePageChange = e => {};

  const [pagination, setPagination] = useState({ limit: 5, total: exportHistoryData.length, current: 1 });
  return (
    <>
      <div className={css('side-drawer-container margin-top-2')}>
        <div className={css('filter-bar gap-2')}>
          <div className={css('search_bar')}>
            <InputTextAtom placeholder="Search here" onChange={() => {}}></InputTextAtom>
          </div>

          <div className={css('kyc-filter')}>
            <SelectAtom
              value={''}
              items={[
                {
                  text: 'ALL',
                  value: 1,
                },
                {
                  text: 'COMPLETE',
                  value: 2,
                },
                {
                  text: 'IN PROGRESS',
                  value: 3,
                },
              ]}
              onChange={() => {}}
              placeholder="Status"
              id="field-control-all-status"
            />
            {/* <Dropdown placeholder="KYC Status" items={kycStatuses}></Dropdown> */}
          </div>
        </div>
        <div className={css('file-list-container')}>
          {exportHistoryData.map((item, index) => (
            <div className={css('file-item')}>
              <div className={css('file-item-icon')}>
                <CSVIconSvg></CSVIconSvg>
              </div>
              <div className={css('file-details')}>
                <div className={css('file-item-title')}>{item.fileName} </div>
                <div className={css('file-item-subtitle')}>
                  Exported By {item.user.name} on Nov 10, 2022 11:50:43 AM
                </div>
              </div>
              <div className={css('file-item-status')}>
                <Badge
                  labelText={item.status}
                  onClickHandler={function noRefCheck() {}}
                  state={item.status === 'VERIFIED' ? '' : item.status === 'PENDING' ? 'warn' : ''}
                />
              </div>
            </div>
          ))}
        </div>
        <div className={css('pagination-container')}>
          <Pagination
            id="merchant_profile_pagination"
            name="Merchant Profile Pagination"
            type={TypeEnum.TYPE_DEFAULT}
            onChange={e => handlePageChange(e)}
            defaultPageSize={5}
            pageSizeOptions={[5, 10, 15, 20]}
            value={pagination}
            style={{ border: '1px solid rgba(0, 0, 0, 0.05)' }}
          />
        </div>
      </div>
    </>
  );
};
export default ExportHistory;
