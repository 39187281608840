import NitrozenOverrideCSS from '../styles/nitrozen-override.module.scss';
import FormBuilderCSS from '../pages/form-builder/components/common.module.scss';
import B2BTheme from '../styles/common/b2b-theme.module.scss';

export const getCompiledClasses = (classNames: string, currentFileCSS?: { [key: string]: string }) => {
  const classList = classNames.split(' ');
  let compiledClasses = [
    classList.map(cls => `${B2BTheme[cls]}`).join(' '),
    currentFileCSS && classList.map(cls => `${currentFileCSS[cls]}`).join(' '),
    classList.map(cls => `${FormBuilderCSS[cls]}`).join(' '),
    classList.map(cls => `${NitrozenOverrideCSS[cls]}`).join(' '),
  ];

  return compiledClasses.join(' ');
};
