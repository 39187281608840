import React from 'react';

// import TextAtom from '../../atoms/text/text';
import CrossIcon from './../../../assets/cross.svg';
// import BorderAtom from '../../atoms/border/border.component';

import CSS from './side-drawer.template.module.scss';

interface ISideDrawer {
  heading: string;
  showDrawer: boolean;
  setShowDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  component: JSX.Element;
  widthPercentage?: number;
}

const SideDrawer: React.FunctionComponent<ISideDrawer> = ({
  heading = '',
  showDrawer = false,
  setShowDrawer,
  component = <></>,
  widthPercentage,
}) => {
  let drawerClasses = CSS['side-drawer'];

  if (showDrawer) {
    drawerClasses = `${CSS['side-drawer']} ${CSS.open}`;
  }

  const handleCrossClick = () => {
    setShowDrawer(!showDrawer);
  };

  const sideDrawerStyle = widthPercentage
    ? Number.isInteger(widthPercentage) && { width: widthPercentage + '%' }
    : { width: 'fit-content' };

  return (
    <>
      <div className={CSS['backdrop']}></div>
      {/* <div className='aaa'>position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 199;
    backdrop-filter: brightness(0.5);</div> */}
      <div className={drawerClasses} style={sideDrawerStyle}>
        <div className={CSS.header}>
          <h6>{heading}</h6>
          <button className={CSS.cross_button} onClick={handleCrossClick}>
            <img src={CrossIcon} alt="cross-icon" />
          </button>
        </div>
        {component}
      </div>
    </>
  );
};

export default React.memo(SideDrawer);
