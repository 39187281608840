export const setCompanyId = (companyId: string | undefined | null) => {
  if (companyId) {
    sessionStorage.setItem('companyId', companyId);
    return true;
  }
  return false;
};

export const getCompanyId = () => {
  return sessionStorage.getItem('companyId') ?? undefined;
};

export const setApplicationInfo = ({
  applicationId,
  token,
  is_active,
  domain,
}: {
  applicationId: string | undefined;
  token?: string | undefined;
  is_active?: boolean | undefined;
  domain?: { name: string } | undefined;
}) => {
  if (applicationId) {
    sessionStorage.setItem('applicationInfo', JSON.stringify({ applicationId, token, is_active, domain }));
    return true;
  }
  return false;
};

export const getApplicationInfo = (): {
  applicationId: string;
  token: string;
  is_active: boolean;
  domain: {
    name: string;
  };
} => {
  return JSON.parse(sessionStorage.getItem('applicationInfo') ?? `{}`);
};

export function pxToRem(pxValue: any) {
  if (isNaN(pxValue)) {
    return pxValue;
  }
  return pxValue / 10 + 'rem';
}

function getFormattedDatesForOneMonth(startDate: any) {
  const formattedDates = [];
  const endDate = new Date();

  while (endDate > startDate) {
    const month = endDate?.getMonth() + 1 > 9 ? endDate?.getMonth() + 1 : `0${endDate?.getMonth() + 1}`;
    const day = endDate.getDate() > 9 ? endDate?.getDate() : `0${endDate?.getDate()}`;
    const x = `${endDate?.getFullYear()}-${month}-${day}`;
    formattedDates.push(x);
    endDate.setDate(endDate.getDate() - 3); // Subtracting 3 days to the current date
  }

  return formattedDates.reverse();
}

export const generateOneMonthIntervals = () => {
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 1);
  const formattedDatesForOneMonth = getFormattedDatesForOneMonth(startDate);
  return formattedDatesForOneMonth;
};

export const formatDate = isoString => {
  const utcDate = new Date(isoString);

  const date = new Date(utcDate.getTime() + 5.5 * 60 * 60 * 1000);

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = months[date.getUTCMonth()];
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();

  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;

  return `${month} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
};

export const saveToLocalStorage = (key, value) => {
  try {
    const serialisedValue = JSON.stringify(value);
    localStorage.setItem(key, serialisedValue);
  } catch (e) {
    console.warn(e);
  }
};

export const loadFromLocalStorage = (key = null) => {
  try {
    if (key) return JSON.parse(localStorage.getItem(key));
    return undefined;
  } catch (e) {
    console.warn(e);
    return undefined;
  }
};



// export const customLogger = {
//   log(module: string = '', logPrefix: string = ) {
//     const args = Array.prototype.slice.call(arguments);
//     args.unshift(logPrefix + ' : ' + module + ' : ');
//     console.log.apply(console, args);
//   },
// };

export class CustomLogger {
  module = '';
  LOG_PREFIX = new Date().toLocaleString('en-IN', {
    timeZone: 'Asia/Kolkata',
  });
  constructor(module = '') {
    this.module = module;
  }

  log() {
    const args = Array.prototype.slice.call(arguments);
    args.unshift(this.LOG_PREFIX + ' : ' + this.module + ' : ');
    console.log.apply(console, args);
  }
}


export const SECONDS = 1000;         // 1 second in milliseconds
export const MINUTES = 60 * SECONDS; // 1 minute in milliseconds
export const HOURS = 60 * MINUTES;   // 1 hour in milliseconds
export const DAYS = 24 * HOURS;      // 1 day in milliseconds
export const WEEKS = 7 * DAYS;       // 1 week in milliseconds
export const MONTHS = 30 * DAYS;     // Approximate 1 month in milliseconds (30 days)
export const YEARS = 365 * DAYS;     // Approximate 1 year in milliseconds (365 days)
