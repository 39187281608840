import CSS from './logs.component.module.scss';
import * as ReactDOMServer from 'react-dom/server';
import { ReactComponent as LogsApproved } from '../../../assets/logs-reapproved.svg';
import { ReactComponent as LogsReject } from '../../../assets/logs-reject.svg';
import { ReactComponent as LogsReuploadRequested } from '../../../assets/logs-reupload-requested.svg';
import { ReactComponent as LogsReuploaded } from '../../../assets/logs-reuploaded.svg';
import { Stepper } from '@gofynd/nitrozen-react';
import { jsx } from 'react/jsx-runtime';
import { getCompiledClasses } from '../../../services/style.service';

import { IStatusLogDocument } from '../verify-merchant.component';
import { formatDate } from '../../../utils/index';

enum StepState {
  'Current' = 'Current',
  'Upcoming' = 'Upcoming',
  'Disabled' = 'Disabled',
  'Issue' = 'Issue',
  'Completed' = 'Completed',
}

const logStatusMap = {
  reupload_requested: {
    label: 'Reupload Requested',
    icon: <LogsReuploadRequested />,
  },
  approved: {
    label: 'Approved',
    icon: <LogsApproved />,
  },
  rejected: {
    label: 'Rejected',
    icon: <LogsReject />,
  },
  uploaded: {
    label: 'Uploaded',
    icon: <LogsReuploaded />,
  },
  reuploaded: {
    label: 'Reuploaded',
    icon: <LogsReuploaded />,
  },
};

const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

const LogsTimeline = ({ statusLogs }: { statusLogs: IStatusLogDocument[] }) => {
  const jsxToHTML = jsxCode => {
    return ReactDOMServer.renderToStaticMarkup(jsxCode);
  };
  return (
    <>
      <div className={css('timeline')}>
        {/* <div className={css('timeline')}> */}
        <div
          className={css('line')}
          style={{
            height: statusLogs.length * 12 + 'rem',
          }}
        ></div>
        {statusLogs.length === 0 && <div className={css('pad-2')}>No logs at the moment.</div>}
        {statusLogs.map((item, index) => (
          <div className={css('timeline-item')}>
            <div className={css('timeline-icon')}>{logStatusMap[item.log_status]?.icon}</div>

            <div className={css('timeline-content')}>
              <div className={css('d-flex gap-1 align-items-center')}>
                <div className={css('timeline-title fs-14')}>
                  {item.document_display_name + ' - ' + logStatusMap[item.log_status]?.label}
                </div>
                {item.log_status !== 'uploaded' && (
                  <div className={css('email fs-12 color-gray')}>{item.updated_by}</div>
                )}
              </div>
              <span className={css('timeline-date')}>{formatDate(item.updated_at)}</span>
              {item.reason && <p className={css('timeline-description')}>{item.reason}</p>}
            </div>
          </div>
        ))}
        <div className={css('whitebox')}></div>
      </div>
    </>
  );
};

export default LogsTimeline;
