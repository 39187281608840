import css from './loader.atom.module.scss';
import LoaderUrl from 'assets/gif/loader.gif';

function Loader() {
  return (
    <div className={css['loader-container']}>
      <img src={LoaderUrl} alt="Loading ..." className={css['loader-gif']} />
    </div>
  );
}

export default Loader;
