import { useEffect, useRef, useState } from 'react';

import { ReactComponent as MerchantProfileSvg } from '../../../assets/merchant-profile.svg';
import { ReactComponent as FormBuilderSvg } from '../../../assets/form-builder.svg';

import CSS from './layout.template.module.scss';

import FormBuilder from '../../../pages/form-builder';
import MerchantProfile from '../../../pages/merchant-profile';

import { Outlet, useNavigate } from 'react-router-dom';
import { getApplicationInfo } from '../../../utils';

import { getCompiledClasses } from '../../../services/style.service';


const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);
const Layout: React.FunctionComponent = () => {
  const [isActive, setActive] = useState(1);
  const [toggle, setToggle] = useState<boolean>();
  const preventConfigUpdate = useRef<boolean>(false);

  useEffect(() => {
    let applicationData = getApplicationInfo();
    setToggle(applicationData?.is_active);

    preventConfigUpdate.current = true;

    menuItem.forEach((item, index) => {
      if (window.location.pathname.includes(item.path)) setActive(index);
    });
  }, []);

  useEffect(() => {}, []);

  const navigate = useNavigate();

  const menuItem = [
    {
      name: 'Form Builder',
      svg: FormBuilderSvg,
      component: FormBuilder,
      path: 'form-builder',
    },
    {
      name: 'Merchant Profile',
      svg: MerchantProfileSvg,
      component: MerchantProfile,
      path: 'merchant-profile',
    },
    // {
    //   name: 'External Endpoints',
    //   svg: APIDashboardSvg,
    //   component: ExternalEndpoints,
    //   path: 'external-endpoints',
    // },
  ];

  const getSvg = (index: number) => {
    const SvgComponent = menuItem[index].svg;
    return <SvgComponent />;
  };

  const handleMenuChange = (path: string, index: number) => {
    setActive(index);
    navigate(path);
  };

  return (
    <div className={css('container')}>
      <div className={css('layout-container position-rel')}>
        <div className={css('feature-menu')}>
          {menuItem.map((item, index) => (
            <div
              className={css(`feature-menu-item ${isActive===index && 'active'}`)}
              key={item.name}
              onClick={() => handleMenuChange(item.path, index)}
            >
              <div className={css('svg-container')}>
                <div className={css('circle')}>{getSvg(index)}</div>
              </div>
              <div className={css('menu-title')}>{item.name}</div>
            </div>
          ))}
        </div>
        <div className={css('feature-wrapper')}>
          <div className={css('feature-component')}>
            <div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
