import React, { useState } from 'react';
import { getCompiledClasses } from '../../../services/style.service';
import CSS from './accordion.atom.module.scss';
// import CSS from './select-country.module.scss';
import { SvgIcChevronDown, SvgIcChevronUp } from '@gofynd/nitrozen-react';
import { COUNTRY_FLAGS } from '../../../pages/form-builder/components/basic-details.component';

interface AccordionProps {
  isOpen: boolean;
  hasBorder?: boolean;
  header: JSX.Element;
  children: JSX.Element;
  props?: any;
}

const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

const Accordion = ({ isOpen = false, header, children, hasBorder = true }: AccordionProps): JSX.Element => {
  const [isActive, setIsActive] = useState(isOpen);
  return (
    <div className={css(`accordion pad-1 ${hasBorder ? 'form-builder-box' : ''}`)}>
      <div className={css('accordion-section')} onClick={() => setIsActive(!isActive)}>
        <div className={css('accordion-header')}>
          {header}

          {isActive ? <SvgIcChevronUp size={20} /> : <SvgIcChevronDown size={20} />}
        </div>
      </div>

      {isOpen && isActive && <div className={css('accordion-content')}>{children}</div>}
    </div>
  );
};

export default Accordion;
