import axios from '../../../services/http.service';
import urlJoin from 'url-join';
import { platformBaseUrl } from '../../../utils/constants';
import { getCompanyId, getApplicationInfo } from '../../../utils';

export const getMerchants = async (
  q: string = '',
  page_no: number = 1,
  page_size: number = 5,
  application_id: string = getApplicationInfo().applicationId,
  company_id: string = getCompanyId(),
  kyc_status: string[] = [],
  country: string[] = []
) => {
  const url = urlJoin(platformBaseUrl, '/merchant-profile/v1/merchants');

  const payload = {
    page_no,
    page_size,
    application_id,
    company_id,
  };

  // q,q,q  aakhir kyunnnnn !?
  if (q) payload['q'] = q;

  // if (kyc_status && kyc_status.length > 0)
  payload['kyc_status'] = kyc_status.map(k => k.toLowerCase());

  // if (country && country.length > 0)
  payload['country'] = country.map(c => c);

  // const response =
  return await axios.post(url, payload);
  // return {
  //   data: {
  //     success: true,
  //     message: 'Fetched All Merchants Successfully',
  //     data: {
  //       documents: [],
  //       page: {
  //         current: 1,
  //         has_next: true,
  //         has_previous: true,
  //         item_total: 0,
  //         total: 0,
  //       },
  //     },
  //   },
  // };
};
export const getMerchantById = async (merchant_id: string = '') => {
  const url = urlJoin(
    platformBaseUrl,
    '/merchant-profile/v1/merchants/' +
      merchant_id +
      '?company_id=' +
      getCompanyId() +
      '&application_id=' +
      getApplicationInfo().applicationId
  );
  return await axios.get(url);
};
export const merchantTableHeaders = [
  // {
  //   customSort: () => {},
  //   name: 'merchant_id',
  //   value: 'Merchant ID',
  //   width: '10%',
  // },
  {
    customSort: () => {},
    name: 'country',
    value: 'Country',
    width: '5%',
  },
  {
    customSort: () => {},
    name: 'first_name',
    value: 'First Name',
    width: '10%',
  },
  {
    customSort: () => {},
    name: 'last_name',
    type: 'number',
    value: 'Last Name',
    width: '10%',
  },
  {
    customSort: () => {},
    name: 'business_legal_name',
    type: 'number',
    value: 'Business Name',
    width: '15%',
  },
  {
    customSort: () => {},
    name: 'phone_number',
    type: 'number',
    value: 'Phone',
    width: '10%',
  },
  {
    customSort: () => {},
    name: 'kyc_status',
    type: 'number',
    value: 'KYC Status',
    width: '10%',
  },
  // {
  //   customSort: () => {},
  //   name: 'registered_on',
  //   type: 'number',
  //   value: 'Registered On',
  //   width: '10%',
  // },
  {
    customSort: () => {},
    name: 'action',
    type: 'number',
    value: 'Action',
    width: '5%',
  },
];

export enum KYC_STATUSES {
  NEW = 'NEW',
  APPROVED = 'APPROVED',
  PARTIALLY_APPROVED = 'PARTIALLY_APPROVED',
  REJECTED = 'REJECTED',
  ON_HOLD = 'ON_HOLD',
}

export const KYC_STATUS_DROPDOWN = [
  // Removing Rejected from dropdown
  // {
  //   text: KYC_STATUSES.REJECTED,
  //   value: KYC_STATUSES.REJECTED,
  // },
  {
    text: KYC_STATUSES.APPROVED,
    value: KYC_STATUSES.APPROVED,
  },
  {
    text: KYC_STATUSES.NEW,
    value: KYC_STATUSES.NEW,
  },
  {
    text: 'PARTIALLY APPROVED',
    value: KYC_STATUSES.PARTIALLY_APPROVED,
  },
  {
    text: KYC_STATUSES.ON_HOLD,
    value: KYC_STATUSES.ON_HOLD,
  },
];

export const exportOptions = [
  {
    text: 'Excel (.xlsx)',
    value: 1,
  },
  {
    text: 'CSV (.csv)',
    value: 1,
  },
];
