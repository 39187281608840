import { createSlice } from '@reduxjs/toolkit';

interface Pagination {
  current: number;
  has_next: boolean;
  item_total: number;
  size: number;
  type: string;
}

export interface IAPIStats {
  api_name: string;
  success_rate: number;
  is_active: boolean;
}

export interface IAPIDataState {
  items: IAPIStats[];
  page: Pagination;
}

const initialState: IAPIDataState = {
  items: [
    {
      api_name: 'Aadhar Verification API',
      success_rate: 98,
      is_active: true,
    },
    {
      api_name: 'GST API',
      success_rate: 50,
      is_active: true,
    },
    {
      api_name: 'tempApi1',
      success_rate: 83,
      is_active: true,
    },
    {
      api_name: 'tempApi1',
      success_rate: 45,
      is_active: true,
    },
    {
      api_name: 'tempApi1',
      success_rate: 72,
      is_active: true,
    },
    {
      api_name: 'tempApi1',
      success_rate: 64,
      is_active: true,
    },
    {
      api_name: 'tempApi1',
      success_rate: 20,
      is_active: true,
    },
    {
      api_name: 'tempApi1',
      success_rate: 70,
      is_active: true,
    },
  ],
  page: {
    current: 1,
    has_next: false,
    item_total: 5,
    size: 5,
    type: 'number',
  },
};

export const apiDashboardSlice = createSlice({
  name: 'apiDashboard',
  initialState,
  reducers: {
    getAPIStatus(state, action) {},
    activateAPI(state, action) {
      state.items.push();
    },
  },
});

export const { activateAPI, getAPIStatus } = apiDashboardSlice.actions;
export default apiDashboardSlice;
