import axios from '../../../services/http.service';
import urlJoin from 'url-join';
import { platformBaseUrl } from '../../../utils/constants';
import { getCompanyId, getApplicationInfo, SECONDS } from '../../../utils';

export const verifyMerchantDocuments = async (merchant_id, payload) => {
  const url = urlJoin(platformBaseUrl, `/merchant-profile/v1/merchants/${merchant_id}/document/status`);
  axios.defaults.timeout = 3 * SECONDS;
  return await axios.put(url, payload);
};
