import './styles/index.scss';
import Breadcrumb from './components/templates/breadcrumb';
import Router from './routes/routes';
import { Provider } from 'react-redux';
import store from './store';

function App() {
  return (
    <div>
      <Provider store={store}>
        <Breadcrumb />
        <Router />
      </Provider>
    </div>
  );
}

export default App;
