import {
  Table,
  Dropdown,
  Input,
  Badge,
  Radio,
  Button,
  SvgIcChevronDown,
  SvgIcChevronUp,
  SvgIcRecentHistory,
  SvgIcDocumentUnknown,
  Dialog,
  SvgIcChevronRightCircle,
  SvgIcChevronLeftCircle,
  Checkbox,
} from '@gofynd/nitrozen-react';
import { DefaultTableData } from '../merchant-profile/molecules/table/table.constants';
import CSS from './verify-merchant.component.module.scss';
import { act, useEffect, useState } from 'react';
import InputTextAtom from '../../components/atoms/input-text/input-text.atom';
import SelectAtom from '../../components/atoms/select/select.atom';
import SideDrawer from '../../components/templates/side-drawer/side-drawer.template';
import { ReactComponent as ViewIcon } from '../../assets/eye-icon.svg';
import { ReactComponent as DocumentIcon } from '../../assets/document-icon.svg';
import { ReactComponent as RejectDocumentIcon } from '../../assets/reject-document.svg';
import { ReactComponent as RejectDocumentFullIcon } from '../../assets/reject-full-icon.svg';
import { ReactComponent as ZoomIconSvg } from '../../assets/zoom-icon.svg';
import RadioInput from '../../components/atoms/radio/radio.atom';
import Modal from '../../components/molecules/modal.molecule';
import LogsTimeline from './components/logs.component';
import { getCompiledClasses } from '../../services/style.service';
import { useSearchParams, useParams } from 'react-router-dom';
import { getMerchantById } from '../merchant-profile/services/merchant-profile.service';
import Loader from '../../assets/gif/loader.gif';
import Accordion from '../../components/atoms/accordion/accordion.atom';
import { verifyMerchantDocuments } from './service/verify-merchant.service';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  IMerchantDocument,
  IMerchantProfile,
  ISelectedMerchant,
  setMerchantDocuments,
  setMerchantProfileDetails,
} from '../../store/slices/verify-merchant.slice';
import ImgsViewer from 'react-images-viewer';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';

import { pdfjs } from 'react-pdf';
import { kycStatusMasterList } from '../merchant-profile/merchant-profile.component';
import Toast from '../../components/atoms/toast/toast.atom';
import { SECONDS } from '../../utils';
import { isEmpty } from 'lodash';
import { getBasicDetailFields, getBusinessDetailFields } from '../form-builder/form-builder.service';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

export interface IStatusLogDocument {
  document_name: string;
  document_display_name?: string;
  reason_id?: string;
  reason: string;
  log_status: string;
  updated_by: string;
  updated_at: Date | string;
}

const VerifyDocument = ({
  images,
  activeIndex,
  setIsDocumentPreviewOpen,
  nextSlide,
  prevSlide,
  document,
  setVerificationDocuments,
  setStatusLogs,
}: {
  images: IBusinessDocumentProof;
  activeIndex: number;
  setIsDocumentPreviewOpen: React.Dispatch<React.SetStateAction<boolean>>;
  nextSlide: () => void;
  prevSlide: () => void;
  document: IBusinessDocument;
  setVerificationDocuments: React.Dispatch<React.SetStateAction<IBusinessDocument[]>>;
  setStatusLogs: React.Dispatch<React.SetStateAction<IStatusLogDocument[]>>;
}) => {
  const [verifyDocumentPayload, setVerifyDocumentPayload] = useState({});
  const [radioSelected, setRadioSelected] = useState('');
  const [otherReasonText, setOtherReasonText] = useState('');

  const dispatch = useDispatch();

  const [zoomed, setZoomed] = useState(false);

  const { merchant_id } = useParams();

  /** Dropdown Document Status Value */
  const [documentStatus, setDocumentStatus] = useState('approved');

  const [saveInProgress, setSaveInProgress] = useState(false);

  const [documentImages, setDocumentImages] = useState([images.front, images.back]);
  const [documentImagesIndex, setDocumentImagesIndex] = useState(0);

  const merchantDetails: IMerchantProfile = useSelector((state: RootState) => state.selectedMerchant.merchant_details);

  // const [documentCurrentStatus, setDocumentCurrentStatus] = useState(document.document_status);
  const documentCurrentStatuses: IMerchantDocument[] = useSelector(
    (state: RootState) => state.selectedMerchant.documents
  );

  const [reason, setReason] = useState('DocumentNotVisible');

  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const handleVerifyDocumentSave = async () => {
    let reasonString = radioSelected;
    if (reasonString === 'Other' + activeIndex) {
      reasonString = otherReasonText;
    } else {
      // Remove numbers and underscores
      let result = reasonString.replace(/[\d_]/g, '');

      // Add spaces between words (assumes words are in camelCase format)
      result = result.replace(/([a-z])([A-Z])/g, '$1 $2');
      // Convert the entire string to lowercase
      result = result.toLowerCase();

      // Capitalize the first word
      result = result.charAt(0).toUpperCase() + result.slice(1);

      reasonString = result;
    }

    if (documentStatus === 'approved') {
      reasonString = 'Approved';
    }

    console.log('reasonString', reasonString);
    console.log('radioSelected', radioSelected);
    console.log('documentStatus', documentStatus);

    const requestPayload = {
      document_name: document.document_name,
      document_display_name: document.document_display_name || document.document_name,
      document_status: documentStatus,
      reason_id: reasonString,
      reason: reasonString,
    };

    try {
      setSaveInProgress(true);
      const verifiedDocs = await verifyMerchantDocuments(merchant_id, requestPayload);
      console.log('verifyMerchantDocuments Response');
      console.log(verifiedDocs.data);
      console.log(verifiedDocs.data?.business_documents);

      const documents: IBusinessDocument[] = [...verifiedDocs.data?.business_documents];

      dispatch(setMerchantDocuments(documents));

      if (verifiedDocs.status === 200) {
        setVerificationDocuments(documents);

        try {
          const res = await getMerchantById(merchant_id);
          console.log('handleVerifyDocumentSave: Getting details for merchant id ' + merchant_id, res.data);

          const merchantProfile: IMerchantProfile = { ...res.data?.data };
          dispatch(setMerchantProfileDetails(merchantProfile));
          if (res.data?.data?.status_logs) {
            const logsForMerchant = [...res.data?.data?.status_logs];
            console.log('🚀 ~ handleVerifyDocumentSave ~ logsForMerchant', logsForMerchant);
            setStatusLogs(logsForMerchant);
          }
          console.log('🚀 ~ handleVerifyDocumentSave ~ response:', merchantProfile);
        } catch (error) {
          console.error(error);
        }

        Toast.success({
          title: 'Document status updated',
          duration: 3 * SECONDS,
        });
        setSaveInProgress(false);
        
      } else {
        setSaveInProgress(false);
        Toast.error({
          title: 'Failed to save document status',
        });
      }
    } catch (error) {
      setSaveInProgress(false);
      Toast.error({
        title: 'Failed to save document status',
      });
      console.error(error);
    }
    setDocumentStatus('approved');
    // window.location.reload();
  };

  useEffect(() => {
    console.log('documentCurrentStatuses', documentCurrentStatuses);
    // console.log(documentCurrentStatuses);
  }, [documentCurrentStatuses]);

  useEffect(() => {
    if (images.front && !images.back && !images.proof_doc) {
      setDocumentImages([images.front]);
    } else if (images.front && images.back && !images.proof_doc) {
      setDocumentImages([images.front, images.back]);
    }

    console.log('documentImages::', documentImages);

    return () => {};
  }, []);

  return (
    <div className={css('d-flex-column pad-2')}>
      <div className={css('document-preview')}>
        {/* {activeIndex !== 0 && (
          <div onClick={() => prevSlide()}>
            <SvgIcChevronLeftCircle size={20} color="#2E31BE" />
          </div>
        )} */}

        {!images.proof_doc && (
          <div className={css('zoom-icon')}>
            <ZoomIconSvg
              onClick={() => {
                console.log('Zoom Icon clicked');
                setIsDocumentPreviewOpen(true);
                setZoomed(true);
              }}
            ></ZoomIconSvg>
          </div>
        )}

        {images.front && !images.back && !images.proof_doc && (
          <>
            <img
              src={images.front}
              alt=""
              onClick={() => {
                setIsDocumentPreviewOpen(true);
                setZoomed(true);
              }}
            />

            {documentImages.length > 0 && (
              <ImgsViewer
                imgs={[{ src: images.front }]}
                isOpen={zoomed}
                onClose={() => setZoomed(false)}
                onClickNext={() => {
                  // setDocumentImagesIndex(documentImagesIndex + 1);
                }}
                onClickPrev={() => {
                  // setDocumentImagesIndex(documentImagesIndex - 1);
                }}
                currImg={documentImagesIndex}
              />
            )}
          </>
        )}

        {images.front && images.back && !images.proof_doc && (
          <>
            <div
              className={css('d-flex-sb w-100')}
              onClick={() => {
                setIsDocumentPreviewOpen(true);
                setZoomed(true);
              }}
            >
              <img
                src={images.front}
                alt=""
                onClick={() => {
                  setZoomed(true);
                  setDocumentImagesIndex(0);
                }}
              />
              <img
                src={images.back}
                alt=""
                onClick={() => {
                  setZoomed(true);
                  setDocumentImagesIndex(1);
                }}
              />
            </div>
            {documentImages.length > 0 && (
              <ImgsViewer
                imgs={[{ src: images.front }, { src: images.back }]}
                isOpen={zoomed}
                onClose={() => setZoomed(false)}
                onClickNext={() => {
                  setDocumentImagesIndex(documentImagesIndex =>
                    documentImagesIndex === documentImages.length - 1 ? 0 : documentImagesIndex + 1
                  );

                  // const prevSlide = () => {
                  //   setActiveIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
                  // };
                }}
                onClickPrev={() => {
                  setDocumentImagesIndex(documentImagesIndex =>
                    documentImagesIndex > 0 ? documentImagesIndex - 1 : 0
                  );
                }}
                currImg={documentImagesIndex}
              />
            )}
          </>
        )}

        {images.proof_doc && (
          <>
            <div className={css('d-flex-center')}>
              <Button
                onClick={() => {
                  window.open(images.proof_doc);
                }}
              >
                Open Document
              </Button>
            </div>
          </>
        )}

        {/* {activeIndex !== images.length - 1 && (
          <div onClick={() => nextSlide()}>
            <SvgIcChevronRightCircle size={20} color="#2E31BE" />
          </div>
        )} */}
      </div>

      {!isEmpty(merchantDetails) && merchantDetails.kyc_status !== 'rejected' && (
        <>
          <div className={css('upload-status')}>
            <div className={css('export-button')}>
              <Dropdown
                addOption={false}
                id="exportOptions"
                placeholder="Re-upload"
                items={[
                  {
                    text: 'Approve',
                    value: 'approved',
                  },
                  {
                    text: 'Re-upload',
                    value: 'reupload_requested',
                  },
                ]}
                value={documentStatus}
                onChange={e => {
                  setDocumentStatus(e);
                }}
              ></Dropdown>
            </div>
          </div>
          {documentStatus === 'reupload_requested' && (
            <div className="radios">
              {/* <select name="" id=""></select> */}
              <RadioInput
                id={'DocumentNotVisible' + activeIndex}
                labelText="Document Not Visible"
                name={'ReuploadReason' + activeIndex}
                onChange={() => {
                  setRadioSelected('DocumentNotVisible' + activeIndex);
                }}
                radioValue={'DocumentNotVisible' + activeIndex}
                value={radioSelected}
              />
              <RadioInput
                id={'WrongDocumentUpload' + activeIndex}
                labelText="Wrong document upload"
                name={'ReuploadReason' + activeIndex}
                onChange={() => {
                  console.log('WrongDocumentUpload');
                  setRadioSelected('WrongDocumentUpload' + activeIndex);
                }}
                radioValue={'WrongDocumentUpload' + activeIndex}
                value={radioSelected}
              />
              <RadioInput
                id={'NumberNotClear' + activeIndex}
                labelText="Number not clear"
                name={'ReuploadReason' + activeIndex}
                onChange={() => {
                  setRadioSelected('NumberNotClear' + activeIndex);
                }}
                radioValue={'NumberNotClear' + activeIndex}
                value={radioSelected}
              />
              <RadioInput
                id={'Other' + activeIndex}
                labelText="Other"
                name={'ReuploadReason' + activeIndex}
                onChange={() => {
                  setRadioSelected('Other' + activeIndex);
                }}
                radioValue={'Other' + activeIndex}
                value={radioSelected}
              />

              {radioSelected === 'Other' + activeIndex && (
                <div className={css('reason-box')}>
                  <InputTextAtom
                    value={otherReasonText}
                    onChange={e => {
                      setOtherReasonText(e);
                    }}
                    placeholder="Enter reason"
                  ></InputTextAtom>
                </div>
              )}
            </div>
          )}
          <div className={css('upload-status-button')}>
            <Button rounded={false} onClick={() => handleVerifyDocumentSave()}>
              {saveInProgress && 'Saving...'}
              {!saveInProgress && 'Save'}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export interface IBusinessDocumentProof {
  front?: string;
  back?: string;
  proof_doc?: string;
}

export interface IBusinessDocument {
  document_id: string;
  document_name: string;
  document_type: string;
  document_display_name: string;
  document_proofs: IBusinessDocumentProof;
  document_uploaded_by: string;
  document_uploaded_at: Date | string;
  reason_id: string;
  reason: string;
  document_status: string;
}

const VerifyMerchant = () => {
  const [activeAccordion, setActiveAccordion] = useState(false);

  const selectedMerchant: ISelectedMerchant = useSelector((state: RootState) => state.selectedMerchant);

  const [merchant, setMerchant] = useState<ISelectedMerchant>(selectedMerchant);

  const dispatch = useDispatch();

  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const [isDocumentPreviewOpen, setIsDocumentPreviewOpen] = useState(false);
  const [isLogSideDrawerOpen, setIsLogSideDrawerOpen] = useState(false);

  const { merchant_id } = useParams();

  const [verificationDocuments, setVerificationDocuments] = useState<IBusinessDocument[]>([]);

  const [verifyMerchantPayload, setVerifyMerchantPayload] = useState({});

  const [statusLogs, setStatusLogs] = useState<IStatusLogDocument[]>([]);

  const [isVerificationDataLoading, setIsVerificationDataLoading] = useState(true);

  const merchantDetails: IMerchantProfile = useSelector((state: RootState) => state.selectedMerchant.merchant_details);
  console.log('🚀 ~ VerifyMerchant ~ merchantDetails:', merchantDetails);

  const [activeIndex, setActiveIndex] = useState(0);
  const [dialogActiveIndex, setDialogActiveIndex] = useState(0);

  const [rejectionReason, setRejectionReason] = useState('');

  const [merchantProfileFields, setMerchantProfileFields] = useState([]);

  const [images, setImages] = useState([
    'https://5.imimg.com/data5/SELLER/Default/2022/5/BA/PO/SO/4373389/pre-printed-aadhar-card-500x500.jpeg',
    'https://5.imimg.com/data5/SELLER/Default/2022/6/FU/YK/QK/47890629/11.png',
  ]);

  const handleRejectAllDocuments = async () => {
    /** keep dialog open */
    // setIsRejectDialogOpen(false);

    for (const doc of verificationDocuments) {
      const requestPayload = {
        document_name: doc.document_name,
        document_display_name: doc.document_display_name || doc.document_name,
        document_status: 'rejected',
        reason_id: rejectionReason,
        reason: rejectionReason,
      };

      try {
        const verifiedDocs = await verifyMerchantDocuments(merchant_id, requestPayload);
        console.log('verifyMerchantDocuments Response');
        console.log(verifiedDocs.data?.data);

        if (verifiedDocs.status === 200) {
          const res = await getMerchantById(merchant_id);
          console.log('Getting details for merchant id ' + merchant_id);

          const merchantProfile: IMerchantProfile = { ...res.data?.data };
          const documents: IMerchantDocument[] = [...res.data?.data?.business_documents];

          dispatch(setMerchantProfileDetails(merchantProfile));
          dispatch(setMerchantDocuments(documents));
          setVerificationDocuments([...res.data?.data?.business_documents]);

          console.log('🚀 ~ merchantProfile:', merchantProfile);
          dispatch(setMerchantProfileDetails(merchantProfile));

          Toast.success({
            title: 'All documents rejected successfully',
            duration: 3 * SECONDS,
            destroy: () => {
              console.log('destroy called');
            },
          });
        } else {
          Toast.error({
            title: 'Failed to reject all documents',
            duration: 3 * SECONDS,
            destroy: () => {},
          });
        }
      } catch (error) {
        Toast.error({
          title: 'Failed to reject all documents',
          duration: 3 * SECONDS,
          destroy: () => {},
        });
        console.error(error);
      }
    }
    // window.location.reload();
  };

  const nextSlide = () => {
    setActiveIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };
  const prevSlide = () => {
    setActiveIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  useEffect(() => {
    setIsVerificationDataLoading(true);

    (async () => {
      try {
        console.log(merchant_id);
        const res = await getMerchantById(merchant_id);
        console.log('Getting details for merchant id ' + merchant_id);
        console.log(res);

        if (res.data?.data?.status_logs) {
          const logsForMerchant = [...res.data?.data?.status_logs];

          setStatusLogs(logsForMerchant);
        }

        const documents: IMerchantDocument[] = [...res.data?.data?.business_documents];

        const merchantProfile: IMerchantProfile = { ...res.data?.data };
        console.log('🚀 ~ merchantProfile:', merchantProfile);
        dispatch(setMerchantProfileDetails(merchantProfile));
        dispatch(setMerchantDocuments(documents));

        setVerificationDocuments([...res.data?.data?.business_documents]);

        // setMerchantDetails({
        //   ...merchantDetails,
        //   first_name: res.data?.data?.first_name,
        //   email: res.data?.data?.email,
        //   business_legal_name: res.data?.data?.business_legal_name,
        //   phone_number: res.data?.data?.phone_number,
        //   country_calling_code: res.data?.data?.country_calling_code,
        //   kyc_status: res.data?.data?.kyc_status,
        //   address: res.data?.data?.address,
        // });

        try {
          console.log('Getting fields ::');
          const basicFields = await getBasicDetailFields(res.data?.data?.country);
          const businessFields = await getBusinessDetailFields(res.data?.data?.country);
          console.log('basicFields ::', basicFields.data.data);
  
          const fields = [...basicFields.data.data, ...businessFields.data.data];
          console.log('🚀 ~ fields:', fields);
  
          setMerchantProfileFields(fields);
        } catch (error) {
          console.error(error);
        }



      } catch (error) {
        console.error(error);
      }

      
    })();

    setIsVerificationDataLoading(false);

    return () => {};
  }, []);

  useEffect(() => {
    console.log('isDocumentPreviewOpen');
    console.log(isDocumentPreviewOpen);
  }, [isDocumentPreviewOpen]);

  return (
    <div className={css('merchant-profile-container')}>
      <div className={css('container-head')}>
        <div className={css('container-head-title')}>
          <div className={css('title')}>Verify Merchant</div>
          <p className={css('subtitle')}>Approve or reject Merchant</p>
        </div>
        <div className={css('d-flex-center options gap-2')}>
          <div
            className={css('color-blue d-flex-center gap-1')}
            onClick={() => {
              setIsLogSideDrawerOpen(true);
            }}
          >
            <SvgIcRecentHistory />
            <span className={css('font-size-14 font-weight-700 cursor-pointer')}>Logs</span>
          </div>

          {!isEmpty(merchantDetails) && merchantDetails.kyc_status !== 'rejected' && (
            <>
              <span className={css('color-light-gray')}>|</span>
              <div className={css('options-red d-flex-center gap-1')}>
                <SvgIcDocumentUnknown />
                <span
                  onClick={() => {
                    setIsRejectDialogOpen(true);
                  }}
                  className={css('font-size-14 cursor-pointer')}
                >
                  {/* <RejectDocumentIcon /> */}
                  Reject & Resubmit
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={css('container-body')}>
        <div className={css('list-card-documents')}>
          {verificationDocuments.length === 0 && (
            <div className={css('color-red fs-14')}>
              <p>No documents found</p>
            </div>
          )}
          {verificationDocuments.map((d, index) => (
            <div className={css('margin-bottom-2')}>
              <Accordion
                isOpen={true}
                hasBorder={true}
                key={1}
                header={
                  <>
                    <div className={css('document-name pad-1')}>
                      <div className={css('d-flex-center gap-2')}>
                        <span>
                          <DocumentIcon />
                        </span>
                        <span className={css('font-size-16 font-weight-600')}>
                          {d.document_display_name || d.document_name.toUpperCase()}
                        </span>
                      </div>
                      <span className={css('d-flex gap-2')}>
                        {/* ['pending', 'approved', 'reupload_requested', 'rejected'] */}
                        {d.document_status === 'reupload_requested' && (
                          <Badge labelText={'RE-UPLOAD'} onClickHandler={function noRefCheck() {}} state={'warn'} />
                        )}
                        {d.document_status === 'rejected' && (
                          <Badge labelText={'REJECTED'} onClickHandler={function noRefCheck() {}} state={'error'} />
                        )}

                        {d.document_status === 'approved' && (
                          <Badge labelText={'APPROVED'} onClickHandler={function noRefCheck() {}} />
                        )}
                        {d.document_status === 'pending' && (
                          <Badge labelText={'PENDING'} state="disable" onClickHandler={function noRefCheck() {}} />
                        )}
                      </span>
                    </div>
                  </>
                }
                children={
                  <VerifyDocument
                    activeIndex={index}
                    images={d.document_proofs}
                    setIsDocumentPreviewOpen={setIsDocumentPreviewOpen}
                    nextSlide={nextSlide}
                    prevSlide={prevSlide}
                    document={d}
                    setVerificationDocuments={setVerificationDocuments}
                    setStatusLogs={setStatusLogs}
                  />
                }
              ></Accordion>
            </div>
          ))}
        </div>
        <div className={css('card-merchant-details')}>
          {isVerificationDataLoading && (
            <>
              <div className={css('loader')}>
                <img src={Loader} alt="loading icon" />
              </div>
            </>
          )}

          {!isVerificationDataLoading && !isEmpty(merchantDetails) && (
            <>
              {merchantProfileFields.some(field => field.field_name === 'first_name') ||
                (merchantProfileFields.some(field => field.field_name === 'last_name') && (
                  <>
                    <div className={css('card-merchant-title')}>Name</div>
                    <div className={css('card-merchant-subtitle')}>
                      {String(merchantDetails.first_name || '')
                        .concat(' ')
                        .concat(merchantDetails.last_name || '')}
                    </div>
                  </>
                ))}

              {merchantProfileFields.some(field => field.field_name === 'email') && (
                <>
                  <div className={css('card-merchant-title')}>Email ID</div>
                  <div className={css('card-merchant-subtitle')}>{merchantDetails.email}</div>
                </>
              )}

              <div className={css('card-merchant-title')}>Merchant ID</div>
              <div className={css('card-merchant-subtitle')}>{merchantDetails.merchant_id}</div>

              {merchantProfileFields.some(field => field.field_name === 'business_name') && (
                <>
                  <div className={css('card-merchant-title')}>Business Name</div>
                  <div className={css('card-merchant-subtitle')}>{merchantDetails.business_legal_name}</div>
                </>
              )}

              {merchantProfileFields.some(field => field.field_name === 'business_name') && (
                <>
                  <div className={css('card-merchant-title')}>Phone Number</div>
                  <div className={css('card-merchant-subtitle')}>
                    {merchantDetails.country_calling_code + ' ' + merchantDetails.phone_number}
                  </div>
                </>
              )}

              {merchantProfileFields.some(field => field.field_name === 'address') && (
                <>
                  <div className={css('card-merchant-title')}>Address</div>
                  <div className={css('card-merchant-subtitle')}>
                    {merchantDetails?.address_line1 ||
                      ''
                        .concat(merchantDetails.address_line2 || '')
                        .concat(' ')
                        .concat(merchantDetails.landmark || '')
                        .concat(' ')
                        .concat(merchantDetails.city || '')
                        .concat(' ')
                        .concat(merchantDetails.state || '')
                        .concat(' ')
                        .concat(merchantDetails.pincode || '')
                        .concat(' ')
                        .concat(merchantDetails.country || '')}
                  </div>
                </>
              )}

              <div className={css('card-merchant-title')}>KYC Status</div>
              <div className={css('card-merchant-subtitle')}>
                {kycStatusMasterList.find(status => status.value === merchantDetails.kyc_status)?.badge}
              </div>
            </>
          )}
        </div>
      </div>

      {isLogSideDrawerOpen && (
        <div className={css('logs-side-drawer')}>
          <SideDrawer
            component={<LogsTimeline statusLogs={statusLogs}></LogsTimeline>}
            heading="Logs"
            setShowDrawer={() => {
              setIsLogSideDrawerOpen(false);
            }}
            showDrawer={true}
            widthPercentage={40}
          ></SideDrawer>
        </div>
      )}

      {isRejectDialogOpen && (
        <Dialog
          kind="dialog"
          size="m"
          closeHandle={() => {
            setIsRejectDialogOpen(false);
          }}
          isVisible={isRejectDialogOpen}
          positiveButtonLabel={'Confirm'}
          negativeButtonLabel={'Cancel'}
          isClosable={true}
          onPositiveResponse={() => {
            handleRejectAllDocuments();
          }}
        >
          <div
            className={`${css('dialog-rejection-confirmation')} ${css('d-flex-column')} ${css('d-flex-center')} ${css(
              'gap-2'
            )}`}
          >
            <div>
              <RejectDocumentFullIcon />
            </div>
            <div>
              <h6 className={css('color-gray')}>Rejection Confirmation</h6>
            </div>
            <div>
              Rejecting the documents will reset the form, requiring the customer to fill it out again. Are you certain
              you want to proceed with rejecting and requesting a resubmission?
            </div>
            <div className={`${css('d-flex-sb')}  ${css('w-100')}`}>
              <div>Reason for Rejection</div>
              <div></div>
            </div>
            <div className={css('w-100')}>
              <InputTextAtom
                onChange={e => {
                  setRejectionReason(e);
                }}
                value={rejectionReason}
                placeholder="Enter reason"
              ></InputTextAtom>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default VerifyMerchant;
