import { useEffect, useState } from 'react';
import CSS from './business-details.module.scss';
import { Dropdown, SvgIcChevronDown, SvgIcChevronUp } from '@gofynd/nitrozen-react';
import { ReactComponent as IndiaFlag } from '../../../assets/india-flag-new.svg';
import {
  ICountryConfig,
  IFormFieldConfig,
  setBusinessFieldsForCountry,
} from '../../../store/slices/form-builder.slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getCompiledClasses } from '../../../services/style.service';

import { ReactComponent as FlagIN } from '../../../assets/flag-in.svg';
import { ReactComponent as FlagUS } from '../../../assets/flag-us.svg';
import { ReactComponent as FlagAE } from '../../../assets/flag-ae.svg';
import { ReactComponent as FlagSA } from '../../../assets/flag-sa.svg';
import Accordion from '../../../components/atoms/accordion/accordion.atom';
import { getBusinessDetailFields, getFormsForApplication } from '../form-builder.service';
import { isEmpty } from 'lodash';

import Loader from 'components/atoms/loader/loader.atom';

const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

export const COUNTRY_FLAGS = {
  India: <FlagIN />,
  'United States of America': <FlagUS />,
  'United Arab Emirates': <FlagAE />,
  'The Kingdom of Saudi Arabia': <FlagSA />,
};

export const businessDetailFieldsList = ['Business Name', 'GST Registered', 'State', 'City', 'Zip/Pincode'];

export const businessDetailFieldMeta: IFormFieldConfig[] = [
  {
    field_name: 'business_name',
    field_display_name: 'Business Name',
    is_required: true,
  },
  {
    field_name: 'gst_register',
    field_display_name: 'GST Registered',
    is_required: true,
  },
  {
    field_name: 'state',
    field_display_name: 'State',
    is_required: true,
  },
  {
    field_name: 'city',
    field_display_name: 'City',
    is_required: true,
  },
  {
    field_name: 'pincode',
    field_display_name: 'Zip/Pincode',
    is_required: true,
  },
];

const BusinessDetailFields = ({ country }: { country: ICountryConfig }) => {
  const dispatch = useDispatch();

  const [businessFields, setBusinessFields] = useState([]);
  const [initBusinessFields, setInitBusinessFields] = useState([]);

  const [businessFieldsLoaded, setBusinessFieldsLoaded] = useState(false);

  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    let rawFields = [];
    (async () => {
      /** Data Loading */
      setIsDataLoading(true);

      const businessFields = await getBusinessDetailFields(country.country_alias);
      console.log('🚀 ~ businessFields:', businessFields.data);

      rawFields = businessFields.data?.data || [];

      const businessFieldsDropdownList = [
        ...rawFields.map(f => ({
          field_name: f.field_name,
          is_required: f.is_required || true,
          field_display_name: f.field_display_name,
        })),
      ];
      setBusinessFields(businessFieldsDropdownList);
      setInitBusinessFields(businessFieldsDropdownList);

      const formsForApplication = await getFormsForApplication();

      // pre load selected fields from API
      const countrySelected = formsForApplication.data?.data.find(
        c => c.country.country_alias === country.country_alias
      );

      if (!isEmpty(countrySelected) && countrySelected?.business_details?.fields?.length > 0) {
        dispatch(
          setBusinessFieldsForCountry([
            country.country_name,
            [
              ...countrySelected?.business_details?.fields.map(f => ({
                field_name: f.field_name,
                is_required: f.is_required || true,
                field_display_name:
                  f.field_display_name ||
                  (rawFields.some(r => r.field_name === f.field_name) &&
                    rawFields.find(r => r.field_name === f.field_name).field_display_name) ||
                  f.field_name ||
                  '',
              })),
            ],
          ])
        );
      }
      setBusinessFieldsLoaded(true);
      setIsDataLoading(false);
    })();
    return () => {};
  }, []);

  const onBusinessDetailFieldSearchInputChange = e => {
    console.log('onBusinessDetailFieldSearchInputChange', e);
    if (e.text === '') {
      setBusinessFields([...initBusinessFields]);
    } else {
      let searchTerms = [];
      if (e.text.includes(' ')) {
        searchTerms = e.text.split(' ');
      } else {
        searchTerms = [e.text];
      }
      const filterResults = [
        ...businessFields.filter(filter =>
          searchTerms.every(term => filter.field_display_name.toLowerCase().includes(term.toLowerCase()))
        ),
      ];
      setBusinessFields([...filterResults]);
    }
  };
  return (
    <>
      {isDataLoading && <Loader />}

      {!isDataLoading && (
        <div>
          <div className={css('pad-1 business-detail-container')}>
            <Dropdown
              addOption={false}
              enableSelectAll={true}
              id="countries"
              items={[
                ...businessFields
                  .map(f => ({
                    text: f.field_display_name,
                    value: f.field_name,
                  }))
                  .filter(d => d.value !== 'gst_num'),
              ]}
              value={country.business_fields.map(a => a.field_name)}
              label="Select Business Fields"
              multiple
              onChange={event => {
                const arr = [...event];
                const fieldsToSet = [];
                arr.map(e => {
                  const field = businessFields.find(f => f.field_name === e);
                  fieldsToSet.push({
                    field_name: field.field_name,
                    is_required: field.is_required,
                    field_display_name: field.field_display_name,
                  });
                });

                dispatch(setBusinessFieldsForCountry([country.country_name, [...fieldsToSet]]));
              }}
              onScroll={function noRefCheck() {}}
              onSearchInputChange={e => onBusinessDetailFieldSearchInputChange(e)}
              placeholder="Select Business Fields"
              searchable={true}
            />
          </div>
          <div>
            <div className={css('d-flex gap-2 margin-1')}>
              {country.business_fields.length > 0 &&
                country.business_fields.map((nested_item, index) => (
                  <div className={css(`pad-1 gap-1 d-flex form-builder-box`)} key={index}>
                    <span>{nested_item.field_display_name}</span>
                    <span
                      onClick={() => {
                        const countryFields: IFormFieldConfig[] = Array.from(country.business_fields);
                        countryFields.splice(index, 1);
                        console.log('country.fields after deleting', countryFields);
                        dispatch(setBusinessFieldsForCountry([country.country_name, [...countryFields]]));
                      }}
                      className={css('hover-cursor-pointer')}
                    >
                      x
                    </span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const AccordionHeader = ({ country }) => {
  return (
    <div className={css('d-flex-center gap-1 pad-1')}>
      <div className={css('flag-container')}>{COUNTRY_FLAGS[country.country_name]}</div>
      <span> {country.country_name} </span>
    </div>
  );
};

export const BusinessDetails = () => {
  const [activeAccordion, setActiveAccordion] = useState(true);

  const countryList: ICountryConfig[] = useSelector((state: RootState) => state.formConfig.form_config);
  const dispatch = useDispatch();
  return (
    <div className={css('d-flex-column gap-2')}>
      {countryList.length === 0 && <span>Please select countries first</span>}
      {countryList.map((country, index) => {
        return (
          <>
            <Accordion
              header={<AccordionHeader country={country}></AccordionHeader>}
              children={<BusinessDetailFields country={country} key={index}></BusinessDetailFields>}
              isOpen={true}
            ></Accordion>
          </>
        );
      })}
    </div>
  );
};

export default BusinessDetails;
