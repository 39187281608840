import { useState } from 'react';
import RadioInput from '../../../components/atoms/radio/radio.atom';
import InputTextAtom from '../../../components/atoms/input-text/input-text.atom';
import { Button } from '@gofynd/nitrozen-react';
import { ICountryConfig } from '../../../store/slices/form-builder.slice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { buildForm } from '../form-builder.service';
// const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

export const FooterConfiguration = () => {
  const [radioSelected, setRadioSelected] = useState('');
  const formIds: string[] = useSelector((state: RootState) => state.formConfig.form_config.map(f => f.form_id));
  const countries: string[] = useSelector((state: RootState) => state.formConfig.form_config.map(f => f.country_name));

  const [isFormSubmissionSuccessful, setIsFormSubmissionSuccessful] = useState(false);

  const submitForm = async () => {
    for (const formId of formIds) {
      const builtForm = await buildForm(formId);
      console.log(builtForm.data);
      if (builtForm.status === 200) {
        console.log(builtForm.data());
        setIsFormSubmissionSuccessful(true);
      } else {
        console.log('Error');
      }
    }
  };
  return (
    <>
      <div className="radios d-flex-column gap-1 pad-edge-1">
        <p
          style={{
            marginBottom: '1rem',
          }}
        >
          You're submitting data for countries
        </p>

        {countries.map(c => (
          <p>{c}</p>
        ))}
        <Button
          rounded={false}
          onClick={() => submitForm()}
          style={{
            width: '10%',
          }}
        >
          Save
        </Button>
      </div>

      {isFormSubmissionSuccessful && (
        <div
          style={{
            color: 'green',
          }}
        >
          Form Submission is successful. Thanks.
        </div>
      )}
    </>
  );
};

export default FooterConfiguration;
