import axios from '../../../services/http.service';
import { getApplicationInfo, getCompanyId } from '../../../utils/index';
import { platformBaseUrl } from '../../../utils/constants';
import { applicationsMockResponse } from '../../../mock/get-applications';

interface QueryParams {
  search?: string;
  limit?: number;
  page?: number;
}

export const getApplications = async (queryParams: QueryParams) => {
  /**
   * @todo adding appropriate endpoints
   */
  const response = await axios.get(platformBaseUrl + '/saleschannel/v1/applications', {
    params: {
      ...queryParams,
    },
  });
  return response.data.data;
  // return applicationsMockResponse;
};

export const getAllBindings = async () => {
  const queryParams = {
    company_id: getCompanyId(),
    application_id: getApplicationInfo().applicationId,
  };
  const response = await axios.get(platformBaseUrl + '/bindings/v1', {
    params: queryParams,
  });
  return response.data;
};

export const addBinding = async () => {
  const queryParams = {
    company_id: getCompanyId(),
    application_id: getApplicationInfo().applicationId,
  };
  console.log('🚀 ~ addBinding ~ platformBaseUrl:', platformBaseUrl);

  const response = await axios.post(
    platformBaseUrl + '/bindings/v1',
    {},
    {
      params: queryParams,
    }
  );
  return response.data;
};
