import { useEffect, useState } from 'react';
import { ICountryConfig, IRule, IRules } from '../../../store/slices/form-builder.slice';
import { AddRuleIcon, dropdownItems, dropdownLabelMap } from './verification-details.component';
import { Dropdown, SvgIcClose, SvgIcTrash } from '@gofynd/nitrozen-react';
import { getCompiledClasses } from '../../../services/style.service';
import CSS from './verification-details.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

export const RuleSeparator = ({ operator, setRuleSeparationOperator }) => {
  // const [operator, setOperator] = useState('AND');
  return (
    <div className={css('rule-separator')}>
      <span className={css('rule-operator-x')}>
        <Dropdown
          items={[
            {
              text: 'AND',
              value: 'AND',
            },
            {
              text: 'OR',
              value: 'OR',
            },
          ]}
          value={operator}
          onChange={e => setRuleSeparationOperator(e)}
        ></Dropdown>
      </span>
    </div>
  );
};
export const RuleFieldSeparator = ({ operator, setOperator }) => {
  // const [operator, setOperator] = useState('AND');
  return (
    <div className={css('rule-separator')}>
      <span className={css('rule-operator-x')}>
        <Dropdown
          items={[
            {
              text: 'AND',
              value: 'AND',
            },
            {
              text: 'OR',
              value: 'OR',
            },
          ]}
          value={operator}
          onChange={e => setOperator(e)}
        ></Dropdown>
      </span>
    </div>
  );
};

export const Rule = ({
  country,
  addSubRuleEnabled,
  setAddSubRuleEnabled,
  allRules,
  conditions,
  action = 'ADD',
  index = 1,
  setRuleConfig,
  deletable = true,
  totalRules,
  setTotalRules,
  totalCategoriesSelectedInRules,
  totalDocsSelected,
  internalRuleOperator = 'AND',
}: {
  country: ICountryConfig;
  addSubRuleEnabled: boolean;
  setAddSubRuleEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  allRules: IRules;
  conditions: string[];
  action?: string;
  index?: number;
  setRuleConfig: React.Dispatch<React.SetStateAction<IRule>>;
  deletable?: boolean;
  totalRules?: number;
  setTotalRules: React.Dispatch<React.SetStateAction<number>>;
  // selectedRuleDocuments: string[];
  totalDocsSelected: any;
  internalRuleOperator?: string;
  totalCategoriesSelectedInRules: string[];
}) => {
  const currentRule: IRule = allRules[`rule#${index}`];
  const [c1, c2] = currentRule.conditions || [];

  const documentCategoriesSelected = useSelector(
    (state: RootState) =>
      state.formConfig.form_config.find(c => c.country_alias === country.country_alias).verification_documents
  );

  const rulesUsed = useSelector(
    (state: RootState) => state.formConfig.form_config.find(c => c.country_alias === country.country_alias).rules
  );

  const [allCategoriesSelectedInRules, setAllCategoriesSelectedInRules] = useState(totalCategoriesSelectedInRules);

  const [condition1, setCondition1] = useState(c1);
  const [condition2, setCondition2] = useState(c2);
  const [operator, setOperator] = useState(currentRule.operator);

  const conditionsSelected = [];
  if (documentCategoriesSelected?.proof_of_address?.length > 0) conditionsSelected.push('POA');
  if (documentCategoriesSelected?.proof_of_business?.length > 0) conditionsSelected.push('POB');
  if (documentCategoriesSelected?.proof_of_identity?.length > 0) conditionsSelected.push('POI');

  const ruleStringsUsed = [];

  console.log('rulesUsed', rulesUsed);
  for (const rule of Object.keys(rulesUsed)) {
    console.log('rule', rule);
    console.log('rule', rulesUsed[rule]);
    if (rule?.includes('rule')) {
      rulesUsed[rule]?.conditions?.forEach(rule => {
        !ruleStringsUsed?.includes(rule) && ruleStringsUsed.push(rule);
      });
    }
  }

  console.log('RULES:: ruleStringsUsed', ruleStringsUsed);

  // if(conditionsSelected.length === 2) {
  //   setCondition1(conditionsSelected[0]);
  //   setCondition2(conditionsSelected[1]);
  // }

  console.log('RULES:: ~ ddlList:', dropdownItems);
  const ddlList = [...conditionsSelected.map(condition => ({ text: dropdownLabelMap[condition], value: condition }))];
  console.log('RULES:: ~ ddlList:', ddlList);

  console.log('RULES:: conditionsSelected', conditionsSelected);

  const [ruleDropdownList, setRuleDropdownList] = useState([]);

  // useEffect(() => {
  //   console.log('--------------------------------', ruleDropdownList);
  //   console.log('RULES:: ruleDropdown:', ruleDropdownList);
  //   const filteredDropdown = [...dropdownItems.filter(item => !conditionsSelected.includes(item.value))];
  //   console.log('RULES:: filteredDropdown on allCategoriesSelectedInRules:', filteredDropdown);
  //   setRuleDropdownList(filteredDropdown);
  // }, []);

  useEffect(() => {
    console.log('RULES:: allCategoriesSelectedInRules', allCategoriesSelectedInRules);
    console.log('RULES:: ruleDropdown:', ruleDropdownList);
    const filteredDropdown = [...dropdownItems.filter(item => !allCategoriesSelectedInRules.includes(item.value))];
    setRuleDropdownList(filteredDropdown);
    console.log('RULES:: filteredDropdown on allCategoriesSelectedInRules:', filteredDropdown);
    return () => {};
  }, [allCategoriesSelectedInRules]);

  useEffect(() => {
    console.log('RULES:: allCategoriesSelectedInRules', allCategoriesSelectedInRules);
    console.log('RULES:: ruleDropdown:', ruleDropdownList);
    const filteredDropdown = [...dropdownItems.filter(item => !allCategoriesSelectedInRules.includes(item.value))];
    setRuleDropdownList(filteredDropdown);
    console.log('RULES:: filteredDropdown on allCategoriesSelectedInRules:', filteredDropdown);

    return () => {};
  }, [totalCategoriesSelectedInRules]);

  useEffect(() => {
    console.log('RULES:: ruleDropdown:', ruleDropdownList);
    const filteredDropdown = [...dropdownItems.filter(item => !allCategoriesSelectedInRules.includes(item.value))];
    console.log('RULES:: filteredDropdown -- first:', filteredDropdown);
    setRuleDropdownList(filteredDropdown);
    // setCondition1(currentRule.conditions[0]);
    // setCondition2(currentRule.conditions[1]);

    const cc = [];
    for (const key of Object.keys(allRules)) {
    }
    return () => {};
  }, []);

  useEffect(() => {
    // setCondition1(currentRule.conditions[0]);
    // setCondition2(currentRule.conditions[1]);
    return () => {};
  }, []);

  useEffect(() => {
    console.log('operator');
    console.log(operator);
    const conditionsCopy = [];

    console.log('condition1', condition1);
    console.log('condition2', condition2);
    console.log('operator', operator);

    if (condition1) {
      conditionsCopy.push(condition1);
    }
    if (condition2) {
      conditionsCopy.push(condition2);
    } else {
      setAddSubRuleEnabled(true);
    }

    console.log(`RULE ${index}`, {
      conditions: conditionsCopy,
      operator: operator,
    });

    setRuleConfig({
      conditions: conditionsCopy,
      operator: operator,
    });

    console.log('RULES:: Rule 1 conditions', allRules['rule#1']?.conditions);
    console.log('RULES:: Rule 2 conditions', allRules['rule#2']?.conditions);

    // setSelectedRuleDocuments(Array.from(new Set([condition1, condition2])));
  }, [operator, condition1, condition2, index]);

  return (
    <>
      <div className={css('d-flex-sb pad-2')}>
        <div>
          <span className={css('fs-14')}>Rule {index}</span>
        </div>

        {deletable && (
          <div
            onClick={() => {
              setCondition1('');
              setCondition2('');
              setRuleConfig({});
            }}
          >
            <SvgIcTrash size={20} />
          </div>
        )}
      </div>

      <div className={css('rule-field pad-1 margin-edge-1')}>
        <div className={css('rule-field-item d-flex-center gap-1')}>
          <Dropdown
            id="ddlStates"
            items={[...ddlList.filter(i => !ruleStringsUsed.includes(i.value))].concat({
              text: dropdownLabelMap[condition1],
              value: condition1,
            })}
            onChange={event => {
              setCondition1(event);
            }}
            value={condition1}
            placeholder="Document Type"
          />
        </div>

        {/* Load AddRule Icon when, only Condition 1 is set or when add SubRule is defined true */}
        {addSubRuleEnabled && condition1 && !condition2 && (
          <div
            onClick={() => {
              setCondition2(dropdownItems.map(i => i.value).find(i => i !== condition1));
            }}
          >
            <AddRuleIcon />
          </div>
        )}

        {condition2 && (
          <>
            <RuleFieldSeparator setOperator={setOperator} operator={operator} />
            <div className={css('rule-field-item d-flex-center gap-1')}>
              <Dropdown
                id="ddlStates"
                items={[...ddlList.filter(i => !ruleStringsUsed.includes(i.value))].concat({
                  text: dropdownLabelMap[condition2],
                  value: condition2,
                })}
                onChange={event => {
                  setCondition2(event);
                }}
                value={condition2}
                placeholder="Document Type"
              />
              <span
                onClick={() => {
                  setCondition2('');
                }}
              >
                <SvgIcClose />
              </span>
            </div>
          </>
        )}
      </div>
    </>
  );
};
