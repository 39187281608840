import { Dropdown, NudgeManager } from '@gofynd/nitrozen-react';
import { useEffect, useState } from 'react';
import CSS from './select-country.module.scss';
import { RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import {
  ICountry,
  ICountryConfig,
  addCountry,
  formBuilderSelector,
  removeCountry,
  setCountries,
  setFieldsForCountry,
  setFormIdForCountry,
} from '../../../store/slices/form-builder.slice';
import { getCompiledClasses } from '../../../services/style.service';
import { deleteForm, getCountries, getFormsForApplication } from '../form-builder.service';

import Loader from 'components/atoms/loader/loader.atom';

const css = (classNames, currentFileCSS = CSS) => getCompiledClasses(classNames, currentFileCSS);

const COUNTRY_LIST = ['India', 'United States of America', 'United Arab Emirates', 'The Kingdom of Saudi Arabia'];

export const countryAliases = {
  India: 'IND',
  'United States of America': 'USA',
  'United Arab Emirates': 'UAE',
  'The Kingdom of Saudi Arabia': 'KSA',
};

const countryNames = {
  IND: 'India',
  USA: 'United States of America',
  UAE: 'United Arab Emirates',
  KSA: 'The Kingdom of Saudi Arabia',
};

export const SelectCountries = ({}) => {
  const countryList: ICountryConfig[] = useSelector((state: RootState) => state.formConfig.form_config);
  const countriesSelected: ICountry[] = countryList.map(c => c.country);

  const [dropdownCountries, setDropdownCountries] = useState([]);
  const [initCountries, setInitCountries] = useState([]);

  const [isDataLoading, setIsDataLoading] = useState(false);

  const dispatch = useDispatch();

  const getCountryList = async () => {
    try {
      const countries = await getCountries();
      return countries.data?.data;
    } catch (error) {
      console.error(error);
    }
  };

  const initCountryList = async () => await getCountryList();

  const deleteCountry = async form_id => {
    try {
      const deletedCountry = await deleteForm(form_id);
      console.log('deletedCountry', deletedCountry);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      const formsForApplication = await getFormsForApplication();
      console.log('formsForApplication.data', formsForApplication.data);

      // Temporary part

      const uniqueCountries = formsForApplication.data?.data || [];

      dispatch(
        setCountries(
          uniqueCountries.map(c => ({
            country_name: c.country.country_name,
            country_alias: c.country.country_alias,
          })) || []
        )
      );

      for (const form of uniqueCountries) {
        if (form?.form_id) {
          dispatch(setFormIdForCountry([form.country.country_name, form?.form_id]));
        }
      }
      setIsDataLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setIsDataLoading(true);
      if (dropdownCountries.length === 0) {
        try {
          // Fetching countries
          console.log('Fetching countries from backend');
          const initCountries = await initCountryList();

          const dropDownCountryList =
            initCountries?.map((country, index) => ({
              value: country.country_alias,
              text: country.country_name,
            })) || [];

          setInitCountries(dropDownCountryList);
          setDropdownCountries(dropDownCountryList);

          setIsDataLoading(false);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, []);

  const onCountrySearchInputChange = e => {
    console.log('onCountrySearchInputChange', e);

    if (e.text === '') {
      setDropdownCountries([...initCountries]);
      console.log('Setting to init');
    } else {
      let searchTerms = [];
      if (e.text.includes(' ')) {
        searchTerms = e.text.split(' ');
      } else {
        searchTerms = [e.text];
      }
      const filterResults = [
        ...initCountries.filter(country =>
          searchTerms.every(term => country.text.toLowerCase().includes(term.toLowerCase()))
        ),
      ];
      setDropdownCountries(filterResults);
      console.log('onCountrySearchInputChange', filterResults);
    }
  };
  return (
    <div className={css('')}>
      {isDataLoading && <Loader />}

      {!isDataLoading && (
        <>
          <Dropdown
            addOption={false}
            enableSelectAll={true}
            id="countries"
            items={[...dropdownCountries]}
            value={countriesSelected.map(c => c.country_alias)}
            label="Countries"
            multiple
            onChange={async event => {
              console.log('dispatching');

              console.log(
                'OLD COUNTRIES',
                countryList.map(c => c.country_alias)
              );

              // find which country was removed and now not available in event arraya which is in countryList
              // const toRemove = countryList.map(c => c.country_alias).filter(c => !event.map(e => e).includes(c));

              const countryToRemove: ICountryConfig[] = [
                ...countryList.filter(c => !event.map(e => e).includes(c.country_alias)),
              ];
              console.log('🚀 ~ SelectCountries ~ toRemove:', countryToRemove);

              dispatch(
                setCountries([
                  ...event.map(e => {
                    const formIdIfExists = countryList.find(c => c.country_alias === e)?.form_id;
                    const obj = {
                      country_name: countryNames[e],
                      country_alias: e,
                    };
                    if (formIdIfExists) obj['form_id'] = formIdIfExists;
                    return obj;
                  }),
                ])
              );

              try {
                if (countryToRemove.length > 0 && countryToRemove[0].form_id) {
                  await deleteCountry(countryToRemove[0]?.form_id);
                }
              } catch (error) {
                console.error('Could not delete country', error);
              }
              // for (const country of toRemove) {
              //   deleteCountry(country);
              // }

              console.log(
                'NEW COUNTRIES',
                countryList.map(c => c.country_alias)
              );

              console.log('dispatched');
            }}
            onScroll={function noRefCheck() {}}
            onSearchInputChange={e => onCountrySearchInputChange(e)}
            // placeholder="Select Countries"
            searchable={true}
          />

          <div className={css('d-flex gap-2 margin-edge-1')}>
            {countriesSelected.length > 0 &&
              countriesSelected.map((item, index) => (
                <div
                  className={css(`pad-1 gap-1 d-flex cancel-div ${!countriesSelected.includes(item) ? 'd-none' : ''}`)}
                  key={index}
                >
                  <span>{item.country_name}</span>
                  <span
                    onClick={async () => {
                      // const newListWithOneRemoved = countriesSelected.filter(c => c.country_alias !== item.country_alias);
                      // console.log('🚀 ~ SelectCountries ~ newListWithOneRemoved:', newListWithOneRemoved);

                      await deleteCountry(countryList.find(c => c.country_alias === item.country_alias)?.form_id);
                      dispatch(removeCountry(item.country_alias));
                    }}
                    className={css('hover-cursor-pointer')}
                  >
                    x
                  </span>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SelectCountries;
