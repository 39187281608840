import axios, { AxiosHeaders } from 'axios';
import { getCompanyId } from '../utils/index';

const zeusAxios = axios.create({
  headers: {
    'x-company-id': undefined,
  },
});

zeusAxios.interceptors.request.use(config => {
  if (config && config.headers) {
    (config.headers as AxiosHeaders).set('x-company-id', getCompanyId());
  }
  return config;
});

export default zeusAxios;
