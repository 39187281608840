import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { apiDashboardSlice } from './slices/external-endpoints.slice';
import { verifyMerchantSlice } from './slices/verify-merchant.slice';
import formConfigSlice from './slices/form-builder.slice';
import debounce from 'lodash/debounce';
import { loadFromLocalStorage, saveToLocalStorage } from '../utils/index';

const stateLocalStorageKey = 'formBuildeState';

const reducers = combineReducers({
  formConfig: formConfigSlice.reducer,
  selectedMerchant: verifyMerchantSlice.reducer,
  apiDashboard: apiDashboardSlice.reducer,
});

const storeConfig = {
  reducer: reducers,
};

// const initState = loadFromLocalStorage(stateLocalStorageKey);

// if (initState) storeConfig['preloadedState'] = initState;

const store = configureStore(storeConfig);

// store.subscribe(
//   debounce(() => {
//     saveToLocalStorage(stateLocalStorageKey, store.getState());
//   }, 1000)
// );

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
