import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import CSS from './breadcrumb.template.module.scss';
import { getCompanyId } from '../../../utils';

const Breadcrumb = () => {
  const pathMap = {
    '/': '/',
    'merchant-profile': 'Merchant Profile',
    'form-builder': 'Form Builder',
    'external-endpoints': 'External Endpoints',
    'select-countries': 'Select Countries',
    'basic-details': 'Basic Details',
    'business-details': 'Business Details',
    'verification-details': 'Verification Details',
    'submit-form': 'Footer Config',
    'verify-merchant': 'Verify Merchant',
  };

  const allowedPaths = Object.keys(pathMap);

  const location = useLocation();
  let pathnames = location.pathname.split('/').filter(x => x);

  /**
   * Remove unwanted paths such as merchant id, mostly parameters in the url
   * merchants/87853423
   * 87853423 should be removed in this case
   */
  for (let index = pathnames.length - 1; index > 0; index--) {
    if (!allowedPaths.includes(pathnames[index])) pathnames.splice(index, 1);
  }

  return (
    <nav aria-label="breadcrumb">
      <ul className={CSS.breadcrumb}>
        <li className={CSS.breadcrumb_item}>
          <Link to={'/company/' + getCompanyId()}>Channels</Link>
        </li>
        {/* <li className={CSS.breadcrumb_item}>
          <Link to="/merchant-profile">Home</Link>
        </li> */}
        {pathnames.map((name, index) => {
          let routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;

          if (routeTo === '/form-builder') routeTo = 'form-builder/select-countries';

          console.log('🚀 ~ {pathnames.map ~ routeTo:', routeTo);

          if (allowedPaths.includes(name)) {
            const isLast = index === pathnames.length - 1;
            console.log('isLast', isLast);
            console.log('name', name);
            console.log('name', pathMap[name]);
            return (
              <li key={name} className={`${CSS.breadcrumb_item} ${isLast ? CSS['active'] : ''}`}>
                <b>
                  {isLast ? (
                    pathMap[name]
                  ) : (
                    <Link
                      to={routeTo}
                      onClick={() => {
                        if (routeTo === 'form-builder/select-countries') {
                          window.location.reload();
                        }
                      }}
                    >
                      {pathMap[name]}
                    </Link>
                  )}
                </b>
              </li>
            );
          } else {
            return <></>;
          }
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumb;
