import React, { memo } from "react";
import { Dropdown } from "@gofynd/nitrozen-react";
import CSS from "./select.atom.module.scss";

interface SelectAtomProps {
  placeholder?: string;
  id?: string;
  items: any[];
  onChange: (value: string) => void;
  value: string | string[] | number | undefined;
  className?: string;
  disabled?: boolean;
}

function Select({
  placeholder,
  id,
  onChange,
  items,
  value,
  className = "",
  disabled = false,
}: SelectAtomProps): JSX.Element {
  return (
    <>
      <div className={CSS["select-wrapper"] + " " + className}>
        <Dropdown
          value={value}
          items={items}
          //   className={CSS["select-font-size"]}
          placeholder={placeholder}
          data-testid={id}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </>
  );
}

const SelectAtom = memo(Select);
export default SelectAtom;
