import {
  SvgIcErrorColored,
  SvgIcInfo,
  SvgIcSuccessColored,
  SvgIcWarningColored,
  ToastManager,
} from '@gofynd/nitrozen-react';
const toastManager = new ToastManager('top-right');

export interface ToastOptions {
  title?: string;
  content?: string;
  position?: string;
  leftIconPosition?: string;
  rightIconPosition?: string;
  destroy?: () => void;
  leftImage?: React.ReactNode;
  rightImage?: React.ReactNode;
  duration?: number;
  shouldClose?: boolean;
}

const Toast = {
  show({
    title = '',
    content = '',
    duration = 5000,
    shouldClose = true,
    position = 'top-right',
    destroy = () => {},
    leftImage = <SvgIcInfo style={{ fontSize: '26px' }} />,
    leftIconPosition = 'center-align',
    ...restProps
  }: ToastOptions) {
    new ToastManager('top-right').show({
      title,
      content,
      duration,
      shouldClose,
      position,
      destroy,
      rightIconPosition: '',
      rightImage: '',
      leftIconPosition,
      leftImage,
      ...restProps,
    });
  },

  info({ title = '', content = '', ...restProps }: ToastOptions) {
    Toast.show({
      title,
      content,
      ...restProps,
    });
  },

  error({
    title = 'Operation Failed',
    content = 'Please try again or contact support',
    ...restProps
  } : ToastOptions) {
    Toast.show({
      title,
      content,
      leftImage: <SvgIcErrorColored style={{ fontSize: '26px' }} />,
    });
  },

  success({
    title = 'Operation Successful',
    content = '',
    ...restProps
  }: ToastOptions) {
    Toast.show({
      title,
      content,
      leftImage: <SvgIcSuccessColored style={{ fontSize: '26px' }} />,
    });
  },
};
export default Toast;
